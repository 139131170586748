// Controllo per vedere se il browser è in Kiosk Mode / Fullscreen
const isKioskMode = () => {
  const appWidth =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const appHeight =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const windowWidth = window.screen.width;
  const windowHeight = window.screen.height;

  return windowWidth === appWidth && windowHeight === appHeight;
};

export default isKioskMode;
