export const APP_ASSETS = [
  {
    url: process.env.REACT_APP_BUCKET_BASEURL + "/global/img/icon/back.svg",
    type: "img",
  },
  {
    url:
      process.env.REACT_APP_BUCKET_BASEURL + "/global/img/icon/ico-no-tap.svg",
    type: "img",
  },
  {
    url:
      process.env.REACT_APP_BUCKET_BASEURL + "/global/img/icon/ico-refresh.svg",
    type: "img",
  },
  {
    url: process.env.REACT_APP_BUCKET_BASEURL + "/global/img/icon/close.svg",
    type: "img",
  },
  {
    url:
      process.env.REACT_APP_BUCKET_BASEURL + "/Emotions/img/icon/miniature.svg",
    type: "img",
  },
  {
    url:
      process.env.REACT_APP_BUCKET_BASEURL +
      "/Emotions/img/icon/change-miniature.svg",
    type: "img",
  },
  {
    url:
      process.env.REACT_APP_BUCKET_BASEURL + "/Emotions/img/icon/zoom-in.svg",
    type: "img",
  },
  {
    url:
      process.env.REACT_APP_BUCKET_BASEURL + "/Emotions/img/icon/zoom-out.svg",
    type: "img",
  },
  {
    url: process.env.REACT_APP_BUCKET_BASEURL + "/Emotions/img/icon/play.svg",
    type: "img",
  },
  {
    url: process.env.REACT_APP_BUCKET_BASEURL + "/Emotions/img/icon/pause.svg",
    type: "img",
  },
  {
    url: process.env.REACT_APP_BUCKET_BASEURL + "/Emotions/img/icon/close.svg",
    type: "img",
  },
  {
    url:
      process.env.REACT_APP_BUCKET_BASEURL +
      "/Emotions/img/icon/arrow-down.svg",
    type: "img",
  },
];
