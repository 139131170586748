const getToolLink = (enabledTools, toolName, additionalConfig) => {
  try {
    const config = { path: '', pathToRemove: '', params: '', ...additionalConfig };
    const lowerCaseToolName = toolName.toLowerCase();
    let tool;

    // Logica speciale per gestire il caso di emotions/neurosales
    // Se si prova a creare il link per questo tool, provo a cercare per entrambi i nomi
    // per prevenire eventuali sbagli sul nome
    const emotionsNames = ['emotions', 'neurosales'];
    if (emotionsNames.includes(lowerCaseToolName)) {
      tool = enabledTools.find((el) => emotionsNames.includes(el.name.toLowerCase()));
    } else {
      tool = enabledTools.find((el) => el.name.toLowerCase() === lowerCaseToolName);
    }

    // Splitto i parametri dall'url
    const [url, currentParams] = tool.url.split('?');

    let newUrl = url;

    // Se presente una parte del path da rimuovere la tolgo
    if (config.pathToRemove) {
      newUrl = newUrl.replace(config.pathToRemove, '');
    }

    // Creo i nuovi parametri
    const newParams = currentParams ? `${currentParams}&${config.params}` : config.params;

    return `${newUrl}${config.path}?${newParams}`;
  } catch (e) {
    console.error(e);
    return '';
  }
};

export default getToolLink;
