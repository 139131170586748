import { gql } from '@apollo/client';

const PROJECT_DATA_TO_RETRIEVE = `
  id
  name
  logo
  vendorLogo
  assetLogin
  hostKey
  assetKey
  feVendorKey
  pdf
  assetsUrl
  displayName
  pageTitles {
    neurosales
  }
  payoff
  neurosalesConfig
  manifestConfig
  enabledTools
  projectUrl
  baseurl
`;

const GET_PROJECT_INFO = gql`
  query getProjectInfoByHost ($host:String!, $url:String) {
    getProjectInfoByHost (host:$host, url:$url) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

const GET_PROJECT_INFO_DEFAULT_OPTIONS = (hostname) => {
  return {
    variables: {
      host: hostname,
      url: window.location.href,
    },
  };
};

const GET_CLIENT_TOKEN = gql`
  query getClientToken($project_id: ID!, $client_id: ID!) {
    getClientToken(project_id: $project_id, client_id: $client_id) {
      token
    }
  }
`;

const GET_CLIENT_TOKEN_DEFAULT_OPTIONS = (project_id, client_id) => {
  return {
    variables: {
      project_id: project_id,
      client_id: client_id,
    },
  };
};

const POLICY_DATA_TO_RETRIEVE = `
  id
  name
  number
  text
`;

export const GET_ALL_POLICIES = gql`
  query getAllPolicies($project_id: ID!) {
    getAllPolicies(project_id: $project_id) {
      ${POLICY_DATA_TO_RETRIEVE}
    }            
  }
`;

export const GET_ALL_POLICIES_DEFAULT_OPTIONS = (project_id) => {
  return {
    variables: {
      project_id,
    },
    fetchPolicy: 'no-cache',
  };
};

const USER_DATA_TO_RETRIEVE = `
      id
      firstName
      lastName
      email
      project_ids
      language
      role
      hasGoogleToken
`;

export const GET_USER_INFO = gql`
  query getUserByJWT($project_id: ID!) {
    getUserByJWT(project_id: $project_id) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

export const GET_USER_INFO_DEFAULT_OPTIONS = (pId, skipCondition = false) => {
  return {
    variables: {
      project_id: pId,
    },
    fetchPolicy: 'network-only',
    skip: skipCondition,
  };
};

export const PUBLISH_PREVIEW_CONFIGURATION_MUTATION = gql`
  mutation publishPreviewConfiguration($project_id: ID!, $previewConfiguration: JSON!) {
    publishPreviewConfiguration(project_id: $project_id, previewConfiguration: $previewConfiguration)
  }
`;

export const PUBLISH_PREVIEW_CONFIGURATION_OPTIONS = (project_id, previewConfiguration) => {
  return {
    variables: {
      project_id,
      previewConfiguration: previewConfiguration,
    },
    fetchPolicy: 'no-cache',
  };
};

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const LOGIN_CLIENT_BY_VIDEOCALL_MUTATION = gql`
  mutation loginClientByVideocall($input: LoginClientByVideocallInput!) {
    loginClientByVideocall(input: $input)
  }
`;

export const LOGIN_CLIENT_BY_VIDEOCALL_OPTIONS = (clientToken) => {
  return {
    variables: {
      input: { clientToken },
    },
    fetchPolicy: 'no-cache',
  };
};

const PRODUCT_DATA_TO_RETRIEVE = `
    name
    label
    title
    default
    roles
`;
export const GET_ALL_PRODUCTS = gql`
query getAllProducts($project_id :ID!){
  getAllProducts(project_id :$project_id){
    ${PRODUCT_DATA_TO_RETRIEVE}
  }
}`;

export const GET_ALL_PRODUCTS_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
  };
};

export const GET_PROJECT_TOOL_STATUS = gql`
  query getProjectToolStatus($project_id: ID!) {
    getProjectToolStatus(id: $project_id) {
      status
      toolName
    }
  }
`;

export const GET_PROJECT_TOOL_STATUS_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
  };
};

const Client = {
  GET_PROJECT_INFO,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
  GET_CLIENT_TOKEN,
  GET_CLIENT_TOKEN_DEFAULT_OPTIONS,
  GET_ALL_POLICIES,
  GET_ALL_POLICIES_DEFAULT_OPTIONS,
  GET_USER_INFO,
  GET_USER_INFO_DEFAULT_OPTIONS,
  PUBLISH_PREVIEW_CONFIGURATION_MUTATION,
  PUBLISH_PREVIEW_CONFIGURATION_OPTIONS,
  LOGOUT,
  LOGIN_CLIENT_BY_VIDEOCALL_MUTATION,
  LOGIN_CLIENT_BY_VIDEOCALL_OPTIONS,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_DEFAULT_OPTIONS,
  GET_PROJECT_TOOL_STATUS,
  GET_PROJECT_TOOL_STATUS_OPTIONS,
};

export default Client;
