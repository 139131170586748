import psl from 'psl';

/**
 * Function to get the Domain.
 * Ex. "www.google.it" => "google.it"
 * If localhost => "localhost"
 */
const GetDomain = () => {
  let domain = 'localhost';

  if (window.location.hostname !== 'localhost') {
    const pslUrl = psl.parse(window.location.hostname);
    domain = pslUrl.domain;
  }

  return domain;
};

export default GetDomain;
