import React from 'react';
import { useStore } from '../store/storeUtils';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import Client from './Client';
import { useMutation } from '@apollo/client';
import getToolLink from '../utils/getToolLink';

const ClientComponent = observer(() => {
  const store = useStore();
  const history = useHistory();
  const [logoutMutation] = useMutation(Client.LOGOUT);

  React.useEffect(() =>
    autorun(() => {
      // Se atterro con un url che contiene il parametro "hostname", cancello i cookie
      const landingUrl = window.document.location.href;
      if (landingUrl.includes('hostname')) {
        logoutMutation()
          .then((res) => {
            store.setLoggedUser(null);
          })
          .catch((err) => console.error(err));
      } else if (store.projectId && store.loggedUser) {
        history.push('/');
      } else {
        // Se non esiste il JWT l'utente non è loggato e lo rimando alla login
        // Creo l'url di reindirizzamento in base all'ambiente in cui ci troviamo

        // Calcolo il link di ritorno
        const backToUrl = new URL(window.location.href);
        const params = new URLSearchParams(backToUrl.search);
        params.delete('hostname');
        let backTo = `${window.location.origin}${window.location.pathname}?${params}`;
        backTo = backTo.replace('/login?', '');

        const goToUrl = getToolLink(store.enabledTools, 'businessplatform', { path: '/login', params: `backTo=${encodeURIComponent(backTo)}` });

        window.location.href = goToUrl;
      }
    }, [store.projectId, store.loggedUser]),
  );

  return <></>;
});

export default ClientComponent;
