export const ALUMNI_SANS = {
    name: 'AlumniSans',
    types: [{
        path: '/global/fonts/Alumni_Sans/AlumniSans-VariableFont_wght.ttf',
    }, {
        path: '/global/fonts/Alumni_Sans/AlumniSans-Italic-VariableFont_wght.ttf',
        style: {
            fontStyle: 'italic'
        }
    }]
};

export const ASAP = {
    name: 'Asap',
    types: [{
        path: '/global/fonts/Asap/Asap-VariableFont_wght.ttf',
    }, {
        path: '/global/fonts/Asap/Asap-Italic-VariableFont_wght.ttf',
        style: {
            fontStyle: 'italic'
        }
    }]
};

export const BE_VIETNAM_PRO = {
    name: 'Be Vietnam Pro',
    types: [{
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-Black.ttf',
        style: {
            fontWeight: 900
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-BlackItalic.ttf',
        style: {
            fontWeight: 900,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-Bold.ttf',
        style: {
            fontWeight: 700,
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-BoldItalic.ttf',
        style: {
            fontWeight: 700,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-ExtraBold.ttf',
        style: {
            fontWeight: 800,
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-ExtraBoldItalic.ttf',
        style: {
            fontWeight: 800,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-ExtraLight.ttf',
        style: {
            fontWeight: 200,
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-ExtraLightItalic.ttf',
        style: {
            fontWeight: 200,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-Italic.ttf',
        style: {
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-Light.ttf',
        style: {
            fontWeight: 300,
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-LightItalic.ttf',
        style: {
            fontWeight: 300,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-Medium.ttf',
        style: {
            fontWeight: 500,
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-Regular.ttf',
        style: {
            fontWeight: 400,
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-SemiBold.ttf',
        style: {
            fontWeight: 600,
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-SemiBoldItalic.ttf',
        style: {
            fontWeight: 600,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-Thin.ttf',
        style: {
            fontWeight: 100,
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-MediumItalic.ttf',
        style: {
            fontWeight: 500,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Be_Vietnam_Pro/BeVietnamPro-ThinItalic.ttf',
        style: {
            fontWeight: 100,
            fontStyle: 'italic'
        }
    }]
};

export const CAVEAT = {
    name: 'Caveat',
    types: [{
        path: '/global/fonts/Caveat/Caveat-VariableFont_wght.ttf',
    }]
};

export const CRIMSON_PRO = {
    name: 'Crimson Pro',
    types: [{
        path: '/global/fonts/Crimson_Pro/CrimsonPro-VariableFont_wght.ttf',
    }, {
        path: '/global/fonts/Crimson_Pro/CrimsonPro-Italic-VariableFont_wght.ttf',
        style: {
            fontStyle: 'italic'
        }
    }]
};

export const GRENZE = {
    name: 'Grenze',
    types: [{
        path: '/global/fonts/Grenze/Grenze-Black.ttf',
        style: {
            fontWeight: 900
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-BlackItalic.ttf',
        style: {
            fontWeight: 900,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-Bold.ttf',
        style: {
            fontWeight: 700,
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-BoldItalic.ttf',
        style: {
            fontWeight: 700,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-ExtraBold.ttf',
        style: {
            fontWeight: 800,
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-ExtraBoldItalic.ttf',
        style: {
            fontWeight: 800,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-ExtraLight.ttf',
        style: {
            fontWeight: 200,
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-ExtraLightItalic.ttf',
        style: {
            fontWeight: 200,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-Italic.ttf',
        style: {
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-Light.ttf',
        style: {
            fontWeight: 300,
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-LightItalic.ttf',
        style: {
            fontWeight: 300,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-Medium.ttf',
        style: {
            fontWeight: 500,
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-Regular.ttf',
        style: {
            fontWeight: 400,
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-SemiBold.ttf',
        style: {
            fontWeight: 600,
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-SemiBoldItalic.ttf',
        style: {
            fontWeight: 600,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-Thin.ttf',
        style: {
            fontWeight: 100,
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-MediumItalic.ttf',
        style: {
            fontWeight: 500,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Grenze/Grenze-ThinItalic.ttf',
        style: {
            fontWeight: 100,
            fontStyle: 'italic'
        }
    }]
};

export const KODCHASAN = {
    name: 'Kodchasan',
    types: [{
        path: '/global/fonts/Kodchasan/Kodchasan-Bold.ttf',
        style: {
            fontWeight: 700,
        }
    }, {
        path: '/global/fonts/Kodchasan/Kodchasan-BoldItalic.ttf',
        style: {
            fontWeight: 700,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Kodchasan/Kodchasan-ExtraLight.ttf',
        style: {
            fontWeight: 200,
        }
    }, {
        path: '/global/fonts/Kodchasan/Kodchasan-ExtraLightItalic.ttf',
        style: {
            fontWeight: 200,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Kodchasan/Kodchasan-Italic.ttf',
        style: {
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Kodchasan/Kodchasan-Light.ttf',
        style: {
            fontWeight: 300,
        }
    }, {
        path: '/global/fonts/Kodchasan/Kodchasan-LightItalic.ttf',
        style: {
            fontWeight: 300,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Kodchasan/Kodchasan-Medium.ttf',
        style: {
            fontWeight: 500,
        }
    }, {
        path: '/global/fonts/Kodchasan/Kodchasan-MediumItalic.ttf',
        style: {
            fontWeight: 500,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Kodchasan/Kodchasan-Regular.ttf',
        style: {
            fontWeight: 400,
        }
    }, {
        path: '/global/fonts/Kodchasan/Kodchasan-SemiBold.ttf',
        style: {
            fontWeight: 600,
        }
    }, {
        path: '/global/fonts/Kodchasan/Kodchasan-SemiBoldItalic.ttf',
        style: {
            fontWeight: 600,
            fontStyle: 'italic'
        }
    }]
};

export const KOHO = {
    name: 'KoHo',
    types: [{
        path: '/global/fonts/KoHo/KoHo-Bold.ttf',
        style: {
            fontWeight: 700,
        }
    }, {
        path: '/global/fonts/KoHo/KoHo-BoldItalic.ttf',
        style: {
            fontWeight: 700,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/KoHo/KoHo-ExtraLight.ttf',
        style: {
            fontWeight: 200,
        }
    }, {
        path: '/global/fonts/KoHo/KoHo-ExtraLightItalic.ttf',
        style: {
            fontWeight: 200,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/KoHo/KoHo-Italic.ttf',
        style: {
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/KoHo/KoHo-Light.ttf',
        style: {
            fontWeight: 300,
        }
    }, {
        path: '/global/fonts/KoHo/KoHo-LightItalic.ttf',
        style: {
            fontWeight: 300,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/KoHo/KoHo-Medium.ttf',
        style: {
            fontWeight: 500,
        }
    }, {
        path: '/global/fonts/KoHo/KoHo-MediumItalic.ttf',
        style: {
            fontWeight: 500,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/KoHo/KoHo-Regular.ttf',
        style: {
            fontWeight: 400,
        }
    }, {
        path: '/global/fonts/KoHo/KoHo-SemiBold.ttf',
        style: {
            fontWeight: 600,
        }
    }, {
        path: '/global/fonts/KoHo/KoHo-SemiBoldItalic.ttf',
        style: {
            fontWeight: 600,
            fontStyle: 'italic'
        }
    }]
};

export const KULIM_PARK = {
    name: 'Kulim Park',
    types: [{
        path: '/global/fonts/Kulim_Park/Kulim_Park-Bold.ttf',
        style: {
            fontWeight: 700,
        }
    }, {
        path: '/global/fonts/Kulim_Park/Kulim_Park-BoldItalic.ttf',
        style: {
            fontWeight: 700,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Kulim_Park/Kulim_Park-ExtraLight.ttf',
        style: {
            fontWeight: 200,
        }
    }, {
        path: '/global/fonts/Kulim_Park/Kulim_Park-ExtraLightItalic.ttf',
        style: {
            fontWeight: 200,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Kulim_Park/Kulim_Park-Italic.ttf',
        style: {
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Kulim_Park/Kulim_Park-Light.ttf',
        style: {
            fontWeight: 300,
        }
    }, {
        path: '/global/fonts/Kulim_Park/Kulim_Park-LightItalic.ttf',
        style: {
            fontWeight: 300,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Kulim_Park/Kulim_Park-Regular.ttf',
        style: {
            fontWeight: 400,
        }
    }, {
        path: '/global/fonts/Kulim_Park/Kulim_Park-SemiBold.ttf',
        style: {
            fontWeight: 600,
        }
    }, {
        path: '/global/fonts/Kulim_Park/Kulim_Park-SemiBoldItalic.ttf',
        style: {
            fontWeight: 600,
            fontStyle: 'italic'
        }
    }]
};

export const LATO = {
    name: 'Lato',
    types: [{
        path: '/global/fonts/Lato/Lato-Bold.ttf',
        style: {
            fontWeight: 700,
        }
    }, {
        path: '/global/fonts/Lato/Lato-BoldItalic.ttf',
        style: {
            fontWeight: 700,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Lato/Lato-ExtraLight.ttf',
        style: {
            fontWeight: 200,
        }
    }, {
        path: '/global/fonts/Lato/Lato-ExtraLightItalic.ttf',
        style: {
            fontWeight: 200,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Lato/Lato-Italic.ttf',
        style: {
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Lato/Lato-Light.ttf',
        style: {
            fontWeight: 300,
        }
    }, {
        path: '/global/fonts/Lato/Lato-LightItalic.ttf',
        style: {
            fontWeight: 300,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Lato/Lato-Regular.ttf',
        style: {
            fontWeight: 400,
        }
    }, {
        path: '/global/fonts/Lato/Lato-SemiBold.ttf',
        style: {
            fontWeight: 600,
        }
    }, {
        path: '/global/fonts/Lato/Lato-SemiBoldItalic.ttf',
        style: {
            fontWeight: 600,
            fontStyle: 'italic'
        }
    }]
};

export const LEXEND_EXA = {
    name: 'Levend Exa',
    types: [{
        path: '/global/fonts/Levenx_Exa/LexendExa-VariableFont_wght.ttf',
    }]
};

export const LOBSTER_TWO = {
    name: 'Lobster Two',
    types: [{
        path: '/global/fonts/Lobster_two/LobsterTwo-Bold.ttf',
        style: {
            fontWeight: 700,
        }
    }, {
        path: '/global/fonts/Lobster_two/LobsterTwo-BoldItalic.ttf',
        style: {
            fontWeight: 700,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Lobster_two/LobsterTwo-Italic.ttf',
        style: {
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Lobster_two/LobsterTwo-Regular.ttf',
    }]
};

export const LORA = {
    name: 'Crimson Pro',
    types: [{
        path: '/global/fonts/Lora/Lora-VariableFont_wght.ttf',
    }, {
        path: '/global/fonts/Lora/Lora-Italic-VariableFont_wght.ttf',
        style: {
            fontStyle: 'italic'
        }
    }]
};

export const MALI = {
    name: 'Mali',
    types: [{
        path: '/global/fonts/Mali/Mali-Bold.ttf',
        style: {
            fontWeight: 700,
        }
    }, {
        path: '/global/fonts/Mali/Mali-BoldItalic.ttf',
        style: {
            fontWeight: 700,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Mali/Mali-ExtraLight.ttf',
        style: {
            fontWeight: 200,
        }
    }, {
        path: '/global/fonts/Mali/Mali-ExtraLightItalic.ttf',
        style: {
            fontWeight: 200,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Mali/Mali-Italic.ttf',
        style: {
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Mali/Mali-Light.ttf',
        style: {
            fontWeight: 300,
        }
    }, {
        path: '/global/fonts/Mali/Mali-LightItalic.ttf',
        style: {
            fontWeight: 300,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Mali/Mali-Medium.ttf',
        style: {
            fontWeight: 500,
        }
    }, {
        path: '/global/fonts/Mali/Mali-MediumItalic.ttf',
        style: {
            fontWeight: 500,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Mali/Mali-Regular.ttf',
        style: {
            fontWeight: 400,
        }
    }, {
        path: '/global/fonts/Mali/Mali-SemiBold.ttf',
        style: {
            fontWeight: 600,
        }
    }, {
        path: '/global/fonts/Mali/Mali-SemiBoldItalic.ttf',
        style: {
            fontWeight: 600,
            fontStyle: 'italic'
        }
    }]
};

export const MARTEL = {
    name: 'Martel',
    types: [{
        path: '/global/fonts/Martel/Martel-Black.ttf',
        style: {
            fontWeight: 900,
        }
    }, {
        path: '/global/fonts/Martel/Martel-Bold.ttf',
        style: {
            fontWeight: 700,
        }
    }, {
        path: '/global/fonts/Martel/Martel-ExtraBold.ttf',
        style: {
            fontWeight: 800,
        }
    }, {
        path: '/global/fonts/Martel/Martel-ExtraLight.ttf',
        style: {
            fontWeight: 200,
        }
    }, {
        path: '/global/fonts/Martel/Martel-Light.ttf',
        style: {
            fontWeight: 300,
        }
    }, {
        path: '/global/fonts/Martel/Martel-Regular.ttf',
        style: {
            fontWeight: 400,
        }
    }, {
        path: '/global/fonts/Martel/Martel-SemiBold.ttf',
        style: {
            fontWeight: 600,
        }
    }]
};

export const MONTSERRAT = {
    name: 'Montserrat',
    types: [{
        path: '/global/fonts/Montserrat/Montserrat-VariableFont_wght.ttf',
    }, {
        path: '/global/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf',
        style: {
            fontStyle: 'italic'
        }
    }]
};

export const NOTO_SANS = {
    name: 'Noto Sans',
    types: [{
        path: '/global/fonts/Noto_Sans/NotoSans-Bold.ttf',
        style: {
            fontWeight: 700,
        }
    }, {
        path: '/global/fonts/Noto_Sans/NotoSans-BoldItalic.ttf',
        style: {
            fontWeight: 700,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Noto_Sans/NotoSans-Italic.ttf',
        style: {
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Noto_Sans/NotoSans-Regular.ttf',
    }]
};

export const NUNITO = {
    name: 'Nunito',
    types: [{
        path: '/global/fonts/Nunito/Nunito-VariableFont_wght.ttf',
    }, {
        path: '/global/fonts/Nunito/Nunito-Italic-VariableFont_wght.ttf',
        style: {
            fontStyle: 'italic'
        }
    }]
};

export const OPEN_SANS = {
    name: 'Open Sans',
    types: [{
        path: '/global/fonts/Open_Sans/OpenSans-VariableFont_wght.ttf',
    }, {
        path: '/global/fonts/Open_Sans/OpenSans-Italic-VariableFont_wght.ttf',
        style: {
            fontStyle: 'italic'
        }
    }]
};

export const OSWALD = {
    name: 'Oswald',
    types: [{
        path: '/global/fonts/Oswald/Oswald-VariableFont_wght.ttf',
    }]
};

export const PODKOVA = {
    name: 'Podkova',
    types: [{
        path: '/global/fonts/Podkova/Podkova-VariableFont_wght.ttf',
    }]
};

export const POPPINS = {
    name: 'Poppins',
    types: [{
        path: '/global/fonts/Poppins/Poppins-Black.ttf',
        style: {
            fontWeight: 900
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-BlackItalic.ttf',
        style: {
            fontWeight: 900,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-Bold.ttf',
        style: {
            fontWeight: 700,
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-BoldItalic.ttf',
        style: {
            fontWeight: 700,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-ExtraBold.ttf',
        style: {
            fontWeight: 800,
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-ExtraBoldItalic.ttf',
        style: {
            fontWeight: 800,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-ExtraLight.ttf',
        style: {
            fontWeight: 200,
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-ExtraLightItalic.ttf',
        style: {
            fontWeight: 200,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-Italic.ttf',
        style: {
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-Light.ttf',
        style: {
            fontWeight: 300,
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-LightItalic.ttf',
        style: {
            fontWeight: 300,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-Medium.ttf',
        style: {
            fontWeight: 500,
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-Regular.ttf',
        style: {
            fontWeight: 400,
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-SemiBold.ttf',
        style: {
            fontWeight: 600,
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-SemiBoldItalic.ttf',
        style: {
            fontWeight: 600,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-Thin.ttf',
        style: {
            fontWeight: 100,
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-MediumItalic.ttf',
        style: {
            fontWeight: 500,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Poppins/Poppins-ThinItalic.ttf',
        style: {
            fontWeight: 100,
            fontStyle: 'italic'
        }
    }]
};

export const QUICKSAND = {
    name: 'Quicksand',
    types: [{
        path: '/global/fonts/Quicksand/Quicksand-VariableFont_wght.ttf',
    }]
};

export const RALEWAY = {
    name: 'Raleway',
    types: [{
        path: '/global/fonts/Raleway/Raleway-VariableFont_wght.ttf',
    }, {
        path: '/global/fonts/Raleway/Raleway-Italic-VariableFont_wght.ttf',
        style: {
            fontStyle: 'italic'
        }
    }]
};

export const ROBOTO = {
    name: 'Roboto',
    types: [{
        path: '/global/fonts/Roboto/Roboto-Bold.ttf',
        style: {
            fontWeight: 700,
        }
    }, {
        path: '/global/fonts/Roboto/Roboto-BoldItalic.ttf',
        style: {
            fontWeight: 700,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Roboto/Roboto-ExtraLight.ttf',
        style: {
            fontWeight: 200,
        }
    }, {
        path: '/global/fonts/Roboto/Roboto-ExtraLightItalic.ttf',
        style: {
            fontWeight: 200,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Roboto/Roboto-Italic.ttf',
        style: {
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Roboto/Roboto-Light.ttf',
        style: {
            fontWeight: 300,
        }
    }, {
        path: '/global/fonts/Roboto/Roboto-LightItalic.ttf',
        style: {
            fontWeight: 300,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Roboto/Roboto-Medium.ttf',
        style: {
            fontWeight: 500,
        }
    }, {
        path: '/global/fonts/Roboto/Roboto-MediumItalic.ttf',
        style: {
            fontWeight: 500,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Roboto/Roboto-Regular.ttf',
        style: {
            fontWeight: 400,
        }
    }, {
        path: '/global/fonts/Roboto/Roboto-SemiBold.ttf',
        style: {
            fontWeight: 600,
        }
    }, {
        path: '/global/fonts/Roboto/Roboto-SemiBoldItalic.ttf',
        style: {
            fontWeight: 600,
            fontStyle: 'italic'
        }
    }]
};

export const RUBIK = {
    name: 'Rubik',
    types: [{
        path: '/global/fonts/Rubik/Rubik-VariableFont_wght.ttf',
    }, {
        path: '/global/fonts/Rubik/Rubik-Italic-VariableFont_wght.ttf',
        style: {
            fontStyle: 'italic'
        }
    }]
};

export const SOURCE_SANS_PRO = {
    name: 'Source Sans Pro',
    types: [{
        path: '/global/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf',
        style: {
            fontWeight: 700,
        }
    }, {
        path: '/global/fonts/Source_Sans_Pro/SourceSansPro-BoldItalic.ttf',
        style: {
            fontWeight: 700,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Source_Sans_Pro/SourceSansPro-ExtraLight.ttf',
        style: {
            fontWeight: 200,
        }
    }, {
        path: '/global/fonts/Source_Sans_Pro/SourceSansPro-ExtraLightItalic.ttf',
        style: {
            fontWeight: 200,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Source_Sans_Pro/SourceSansPro-Italic.ttf',
        style: {
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Source_Sans_Pro/SourceSansPro-Light.ttf',
        style: {
            fontWeight: 300,
        }
    }, {
        path: '/global/fonts/Source_Sans_Pro/SourceSansPro-LightItalic.ttf',
        style: {
            fontWeight: 300,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Source_Sans_Pro/SourceSansPro-Medium.ttf',
        style: {
            fontWeight: 500,
        }
    }, {
        path: '/global/fonts/Source_Sans_Pro/SourceSansPro-MediumItalic.ttf',
        style: {
            fontWeight: 500,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf',
        style: {
            fontWeight: 400,
        }
    }, {
        path: '/global/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf',
        style: {
            fontWeight: 600,
        }
    }, {
        path: '/global/fonts/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf',
        style: {
            fontWeight: 600,
            fontStyle: 'italic'
        }
    }]
};

export const TITILLIUM_WEB = {
    name: 'Titillium Web',
    types: [{
        path: '/global/fonts/Titillium_Web/TitilliumWeb-Black.ttf',
        style: {
            fontWeight: 900,
        }
    }, {
        path: '/global/fonts/Titillium_Web/TitilliumWeb-Bold.ttf',
        style: {
            fontWeight: 700,
        }
    }, {
        path: '/global/fonts/Titillium_Web/TitilliumWeb-BoldItalic.ttf',
        style: {
            fontWeight: 700,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Titillium_Web/TitilliumWeb-ExtraLight.ttf',
        style: {
            fontWeight: 200,
        }
    }, {
        path: '/global/fonts/Titillium_Web/TitilliumWeb-ExtraLightItalic.ttf',
        style: {
            fontWeight: 200,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Titillium_Web/TitilliumWeb-Italic.ttf',
        style: {
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Titillium_Web/TitilliumWeb-Light.ttf',
        style: {
            fontWeight: 300,
        }
    }, {
        path: '/global/fonts/Titillium_Web/TitilliumWeb-LightItalic.ttf',
        style: {
            fontWeight: 300,
            fontStyle: 'italic'
        }
    }, {
        path: '/global/fonts/Titillium_Web/TitilliumWeb-Regular.ttf',
        style: {
            fontWeight: 400,
        }
    }, {
        path: '/global/fonts/Titillium_Web/TitilliumWeb-SemiBold.ttf',
        style: {
            fontWeight: 600,
        }
    }, {
        path: '/global/fonts/Titillium_Web/TitilliumWeb-SemiBoldItalic.ttf',
        style: {
            fontWeight: 600,
            fontStyle: 'italic'
        }
    }]
};

export const URBANIST = {
    name: 'Urbanist',
    types: [{
        path: '/global/fonts/Urbanist/Urbanist-VariableFont_wght.ttf',
    }, {
        path: '/global/fonts/Urbanist/Urbanist-Italic-VariableFont_wght.ttf',
        style: {
            fontStyle: 'italic'
        }
    }]
};

export const VOLLKORN = {
    name: 'Vollkorn',
    types: [{
        path: '/global/fonts/Vollkorn-VariableFont_wght.ttf',
    }, {
        path: '/global/fonts/Vollkorn-Italic-VariableFont_wght.ttf',
        style: {
            fontStyle: 'italic'
        }
    }]
};

export const ALL_FONTS = [
    ALUMNI_SANS,
    ASAP,
    BE_VIETNAM_PRO,
    CAVEAT,
    CRIMSON_PRO,
    GRENZE,
    KODCHASAN,
    KOHO,
    KULIM_PARK,
    LATO,
    LEXEND_EXA,
    LOBSTER_TWO,
    LORA,
    MALI,
    MARTEL,
    MONTSERRAT,
    NOTO_SANS,
    NUNITO,
    OPEN_SANS,
    OSWALD,
    PODKOVA,
    POPPINS,
    QUICKSAND,
    RALEWAY,
    ROBOTO,
    RUBIK,
    SOURCE_SANS_PRO,
    TITILLIUM_WEB,
    URBANIST,
    VOLLKORN
];