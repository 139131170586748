import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { isIPad13 } from 'react-device-detect';
import { useStore } from '../../store/storeUtils';
import ServiceWorkerProgressBar from './ServiceWorkerProgressBar';
import FullscreenToggler from './FullscreenToggler';
import isKioskMode from '../../utils/isKioskMode';
import { isCmsEnable } from './../../utils/utils';

const OverlayHandler = observer(() => {
  const store = useStore();

  const [swProgress, setSwProgress] = useState(0);
  const [showSwProgress, setShowSwProgress] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);

  useEffect(() => {
    if (typeof store.numAssets === "number" && store.numAssets !== 0) {
      if (store.numAssetsLoaded + store.numAssetsError === store.numAssets) {
        setDownloadComplete(true);
        setTimeout(() => {
          setShowSwProgress(false);
        }, 3000);
      } else {
        caches.has("preloadCached").then(function (cacheExist) {
          if (cacheExist) {
            setShowSwProgress(false);
          } else {
            setShowSwProgress(true);
          }
        });
        setSwProgress(
          store.numAssets
            ? ((store.numAssetsLoaded + store.numAssetsError) /
                store.numAssets) *
                100
            : 0
        );
      }
    }
  }, [store.numAssetsLoaded, store.numAssetsError, store.numAssets]);

  return showSwProgress ? (
    <ServiceWorkerProgressBar
      swProgress={swProgress}
      downloadComplete={downloadComplete}
      logo={process.env.REACT_APP_BUCKET_BASEURL + store.getSmallLogoNavbar()}
    />
  ) : (process.env.REACT_APP_DEV_MODE !== "true" || store.testMode) &&
    !isIPad13 &&
    !isKioskMode() &&
    !isCmsEnable(store.loggedUser) ? (
    <FullscreenToggler />
  ) : null;
});

export default OverlayHandler;
