import React from 'react';
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import { styled } from '@material-ui/styles';

const BorderLinearProgress = styled(LinearProgress)(({
  borderRadius: 5,
  [`&.MuiLinearProgress-colorPrimary`]: {
    backgroundColor: '#ffffff',
  },
  [`& .MuiLinearProgress-barColorPrimary`]: {
    borderRadius: 5,
    backgroundColor: '#6bc1b4',
  },
}));

const ServiceWorkerProgressBar = (props) => {

    return (
      <div className="fullscreen-overlay-sw-progress">
        <div className="sw-progress-container">
          <div className="sw-progress-logo"
            style={{
              backgroundImage: `url('${props.logo}')`,
            }}
          />
          <span className="sw-progress-text sw-progress-title">{props.downloadComplete ? <>Caricamento completato</> : <>Caricamento...</>}</span>
          <span className="sw-progress-text sw-progress-info">{props.downloadComplete ? <>Il download è terminato,<br /> ora puoi iniziare ad utilizzare Emotions</> : <>Stiamo configurando tutti i contenuti, <br />attendi un attimo.</>}</span>
          <Box style={{ width: '100%', height: '6%' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', position: 'unset' }}>
                <div className="progress-bar-container">
                  <Box style={{ maxWidth: '400px', width: '80%' }}>
                    <BorderLinearProgress variant="determinate" value={props.swProgress} />
                  </Box>
                </div>
            </Box>
          </Box>
          <span className="sw-progress-text sw-progress-percentage">{`${Math.round(props.swProgress)}%`}</span>
        </div>
    </div>
    );
};

export default ServiceWorkerProgressBar;
