import CookiesUtils from '../../utils/cookiesUtils';
import GetDomain from '../../utils/GetDomain';

const cookieOptions = {
  domain: GetDomain(),
};

export const updateCallCookie = (roomId, isVendor, clientId) => {
  try {
    const options = {
      ...cookieOptions,
      expires: new Date(new Date().getTime() + 60 * 1000), // 30sec
    };

    const callCookieContent = {
      roomId,
      isVendor,
      clientId,
    };

    CookiesUtils.setVideocall(JSON.stringify(callCookieContent), options);
  } catch (e) {
    console.log(e);
  }
};

export const deleteCallCookie = () => {
  try {
    CookiesUtils.removeVideocall(cookieOptions);

    console.log('Call Cookie deleted');
  } catch (e) {
    console.log(e);
  }
};

export const getCallCookie = () => {
  try {
    const videocallCookie = CookiesUtils.getVideocall();
    if (videocallCookie) {
      return JSON.parse(CookiesUtils.getVideocall());
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};
