import CookiesUtils from './cookiesUtils';

/**
 * gets actual host
 * @todo remove this utility
 */
const DetectUrl = () => {
  let hostname =
    window.location.hostname.indexOf('www.') >= 0
      ? window.location.hostname
      : 'www.' + window.location.hostname;

  const urlParams = new URLSearchParams(window.location.search);
  const hostnameParam = urlParams.get('hostname');

  const getHostname = () => {
    let hostname1 = localStorage["myhome_dev_hostname"];
    if(hostname1 == null) hostname1 =  localStorage["cms_dev_hostname"];
    return hostname1
  }

  if (
    hostname.indexOf('localhost') >= 0 ||
    hostname.indexOf('.ddns.net') >= 0 ||
    hostname.indexOf('192.168.') >= 0 ||
    hostname.indexOf('biz-tecma') >= 0 ||
    hostname.indexOf('-demo.tecmasolutions.com') >= 0
  ) {
    if (hostnameParam && hostnameParam.length > 0 && hostnameParam !== '') {
      let hostname1 = getHostname()
      if (hostname1 && hostname1 !== hostnameParam) {
        CookiesUtils.removeClientData();
        CookiesUtils.removeClientId();
        CookiesUtils.removeMyHome();
        caches.delete("neurosales-bucket-cache");
        caches.delete("preloadCached");
        sessionStorage.clear();
      }
      localStorage.clear();
      delete localStorage['myhome_dev_hostname'];
      localStorage.setItem('myhome_dev_hostname', hostnameParam);
    }

    let myhome_dev_hostname = getHostname()

    if (myhome_dev_hostname && myhome_dev_hostname.length > 0 && myhome_dev_hostname !== '') {
      return myhome_dev_hostname;
    }

    hostname = process.env.REACT_APP_HOST;
  }

  return hostname;
};

export default DetectUrl;
