import React from 'react';
import { useFullScreen } from 'react-browser-hooks';
import { useStore } from '../../store/storeUtils';

const FullscreenToggler = () => {
  const fs = useFullScreen();

  const store = useStore();

  return (
    <div className={`fullscreen-overlay ${!store.goFullscreen ? 'hide' : fs.fullScreen && 'hide'}`} data-testid="fullscreen-wrapper">
      {/* <h1 className="fullscreen-message">
        {" "}
        Migliora la tua esperienza visualizzando Neurosales in fullscreen
      </h1> */}
      <button
        className="fullscreen-button click-always-enabled videochat-no-remote-click"
        onClick={fs.toggle}
        data-testid="fullscreen-button"
      >
        {fs.fullScreen ? 'Esci' : "Start the Experience"}
      </button>
    </div>
  );
};

export default FullscreenToggler;
