import React, { useEffect, useRef, useState } from "react";
import getParameterByName from "./utils/getParameterByName";
import Peer from "simple-peer";
import { useSocket } from "./socketUtils";
import {
  addListenerVideoMulti,
  getElementClick,
  getElementClickIndexChild,
  getScreenStream,
  getWebcamStream,
} from "./videocallUtils";
import clsx from "clsx";
import {
  Fade,
  Grid,
  Grow,
  IconButton,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import VideocallDialog from "./VideocallDialog";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import CallEndIcon from "@material-ui/icons/CallEnd";
import ScreenShareOutlinedIcon from "@material-ui/icons/ScreenShareOutlined";
import { useStore } from "../store/storeUtils";
import {
  FULLSCREEN_CLIENT_CHECK_EVENT,
  FULLSCREEN_EVENT,
  RESUME_VIDEOCALL,
  SCREEN_SHARING,
} from "./utils/videocallEvents";
import Draggable from "react-draggable";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import PhotoSizeSelectSmallIcon from "@material-ui/icons/PhotoSizeSelectSmall";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import Client from "../client/Client";
import { isMobile } from "react-device-detect";
import { useFullScreen } from "react-browser-hooks/lib/hooks/fullscreen";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import "./styles.scss";
import psl from "psl";
import {
  deleteCallCookie,
  updateCallCookie,
} from "./utils/VideocallCookieUtils";
import getToolLink from "../utils/getToolLink";

const Video = ({ peer }) => {
  const ref = useRef();

  useEffect(() => {
    peer.peer.on("stream", (stream) => {
      ref.current.srcObject = stream;
    });
    // eslint-disable-next-line
  }, []);

  return <video playsInline autoPlay ref={ref} />;
};

const Videocall = () => {
  const [peers, setPeers] = useState([]);
  const [isCallInProgress, setIsCallInProgress] = useState(false);
  const [audioFlag, setAudioFlag] = useState(true);
  const [videoFlag, setVideoFlag] = useState(true);
  const [isCallTerminated, setIsCallTerminated] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [userUpdate, setUserUpdate] = useState([]);
  const [reconnectionTimeout, setReconnectionTimeout] = useState(0);
  const [isVendorDown, setIsVendorDown] = useState(false);
  const [videochatInfoMessage, setVideochatInfoMessage] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [screenSharingId, setScreenSharingId] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isBigVideo, setIsBigVideo] = useState(false);
  const [showOtherControls, setShowOtherControls] = useState(false);
  const [otherVideosContainerPosition, setOtherVideosContainerPosition] =
    useState(null);
  const [showInvalidLinkError, setShowInvalidLinkError] = useState(false);

  const roomId = useRef(getParameterByName("roomId"));
  const hostname = useRef(getParameterByName("hostname"));
  const isVendor = useRef(getParameterByName("isVendor"));
  const clientId = useRef(getParameterByName("clientId"));
  const vfRef = useRef(getParameterByName("vf"));
  const vtRef = useRef(getParameterByName("vt"));
  const userVideo = useRef();
  const peersRef = useRef([]);

  const enableRemoteClickRef = useRef();
  const cookieRefresherRef = useRef();
  const reconnectionIntervalRef = useRef();
  const checkClientFullscreen = useRef(!isMobile);
  const previousPeersLengthRef = useRef(0);
  const currentLocationRef = useRef("");
  const noFullscreenUsersRef = useRef([]);
  const draggableEntityRef = useRef();
  const videocallUrlRef = useRef(document.location.href);

  const history = useHistory();
  const store = useStore();
  const socket = useSocket();
  const fs = useFullScreen();

  const [getClientToken, clientToken] = useLazyQuery(
    Client.GET_CLIENT_TOKEN,
    Client.GET_CLIENT_TOKEN_DEFAULT_OPTIONS(store.projectId, clientId.current)
  );
  const [loginClientByVideocall] = useMutation(
    Client.LOGIN_CLIENT_BY_VIDEOCALL_MUTATION
  );
  const [logout] = useMutation(Client.LOGOUT);
  const [getUserInfo, userInfo] = useLazyQuery(
    Client.GET_USER_INFO,
    Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId)
  );

  // const neurosalesOrEmotions = () => {
  //   return window.location.hostname.includes('localhost') || window.location.hostname.includes('neurosales') ? 'neurosales' : 'emotions';
  // };

  let domain = "localhost";
  if (hostname.current !== "localhost") {
    const pslUrl = psl.parse(hostname.current);
    domain = pslUrl.domain;
  }
  const cookieOptionsSet = {
    expires: 1, // durata di 1 giorno, se non viene messa la durata diventa un session cookie che viene eliminato dopo aver chiuso il browser
    domain: domain,
  };

  useEffect(() => {
    // Se è presente un parametro roomId, inizializzo la videocall
    if (roomId.current) {
      sessionStorage.setItem("videocallMode", true);

      // Se atterro con un url che contiene il parametro "hostname", cancello i cookie
      const landingUrl = window.document.location.href;

      const savedHostname =
        localStorage["myhome_dev_hostname"] || localStorage["cms_dev_hostname"];

      if (
        hostname?.current !== savedHostname &&
        !landingUrl.includes("/quotation") &&
        roomId.current
      ) {
        try {
          logout();
        } catch (e) {
          console.error(e);
        }
      }

      Cookies.set("clientId", clientId.current, cookieOptionsSet);

      socket.on("all-users", (data) => {
        const peers = [];
        data.usersInThisRoom.forEach((userId) => {
          const peer = createPeer(
            userId,
            socket.id,
            userVideo.current.srcObject
          );
          peersRef.current.push({
            peerId: userId,
            peer,
          });
          peers.push({
            peerId: userId,
            peer,
          });
        });
        setPeers(peers);

        // Se sono il vendor invio il clientToken ai client
        // if (isVendor.current) {
        //   emitClientToken();
        // }

        // Se la chiamata è ancora in corso, mi ricollego
        if (data.isCallInProgress) {
          setReconnectionTimeout(5);

          reconnectionIntervalRef.current = setInterval(() => {
            handleReconnectionInterval();
          }, 1000);
        }
      });

      socket.on("user-joined", (payload) => {
        const item = peersRef.current.find(
          (p) => p.peerId === payload.callerID
        );
        if (!item) {
          const peer = addPeer(
            payload.signal,
            payload.callerID,
            userVideo.current.srcObject
          );
          const peerObj = {
            peerId: payload.callerID,
            peer,
          };

          peersRef.current.push(peerObj);

          setPeers([...peersRef.current]);

          // Se sono il vendor invio il clientToken ai client
          if (isVendor.current) {
            getClientToken();
          }
        }
      });

      socket.on("user-left", (id) => {
        const peerObj = peersRef.current.find((p) => p.peerId === id);
        if (peerObj) {
          peerObj.peer.destroy();
        }
        const peers = peersRef.current.filter((p) => p.peerId !== id);
        peersRef.current = peers;

        // Rimuovo eventualmente l'id del peer anche dalla lista di utenti senza fullscreen
        noFullscreenUsersRef.current = noFullscreenUsersRef.current.filter(
          (el) => el !== id
        );
        if (noFullscreenUsersRef.current.length > 0) {
          setVideochatInfoMessage(
            `${
              noFullscreenUsersRef.current.length > 1
                ? "Multiple customers are not in fullscreen mode"
                : "The client is not in fullscreen mode"
            }`
          );
        } else {
          setVideochatInfoMessage("");
        }

        setPeers([...peersRef.current]);
      });

      socket.on("receiving-returned-signal", (payload) => {
        const item = peersRef.current.find((p) => p.peerId === payload.id);
        item.peer.signal(payload.signal);
      });

      socket.on("change", (payload) => {
        setUserUpdate(payload);
      });

      socket.on("start-videocall", () => {
        startVideocall();
      });

      socket.on("terminate-videocall", ({ isVendorConnectionError }) => {
        setIsCallTerminated(true);
        setIsCallInProgress(false);

        handleAudio(false);
        handleVideo(false);
        deleteCallCookie();

        if (isVendorConnectionError) {
          setIsVendorDown(true);
        }

        logout();
        // stopCookieRefresh();
        socket.disconnect();
      });

      socket.on("invalid-link", () => {
        setShowInvalidLinkError(true);
      });

      store.setVideocallMode(true);

      videocallInit();

      if (isVendor.current) {
        prepareVendor();
      } else {
        prepareClient();
      }
    }
    // eslint-disable-next-line
    return () => {
      try {
        sessionStorage.removeItem("videocallMode");
        logout();
        if (socket) {
          socket.disconnect();
        }
      } catch (e) {
        console.error(e);
      }
    };
  }, [socket?.connected]);

  useEffect(() => {
    if (store.projectId && isVendor.current && !userInfo.called) {
      getUserInfo();
    }
    // eslint-disable-next-line
  }, [store.projectId]);

  useEffect(() => {
    if (
      userInfo &&
      !userInfo.loading &&
      userInfo.called &&
      !userInfo.data?.getUserByJWT?.id
    ) {
      // Se la chiamata getUserInfo non mi torna un id utente, vuol dire che il vendor non esiste e allora lo porto alla login
      // Calcolo l'url di ritorno che sarebbe l'attuale senza eventualmente il parametro hostname
      const backToUrl = new URL(window.location.href);
      const backToParams = new URLSearchParams(backToUrl.search);
      backToParams.delete("hostname");
      const backTo = `${window.location.origin}${window.location.pathname}?${backToParams}`;

      // Calcolo il link puntando alla login di businessplatform aggiungendo il parametro necessario a tornare verso questa pagina
      const goToUrl = getToolLink(store.enabledTools, "businessplatform", {
        path: "/login",
        params: `backTo=${encodeURIComponent(backTo)}`,
      });

      window.location.href = goToUrl;
    }
    // eslint-disable-next-line
  }, [userInfo]);

  const prepareVendor = async () => {
    const body = document.body;
    body.classList.add("i-am-the-vendor");
    store.setVideocallIsVendor(true);

    // Timeout, started on mousedown, triggers the beginning of a hold
    // let holdStarter = null;
    // Milliseconds to wait before recognizing a hold
    // let holdDelay = 500;
    // Indicates the user is currently holding the mouse down
    let holdActive = false;

    let video = null;

    //GESTIONE CLICK + VIDEO
    document.addEventListener("click", (ev) => {
      if (ev.target) {
        let elemClick = getElementClick(ev.target);

        //LOGICA DI LOGIN PER FEDRO!
        if (
          elemClick &&
          elemClick.href &&
          elemClick.href.indexOf("home-configurator") >= 0 &&
          elemClick.href.indexOf("login") >= 0
        ) {
          return false;
        }
        if (ev.target.type === "submit" && ev.target.value === "login") {
          try {
            let jwt = sessionStorage.getItem("jwt");
            if (jwt) {
              socket.emit("login-hc-event", {
                roomId: roomId.current,
                token: jwt,
              });
            }
          } catch (e) {
            console.error(e);
          }
        }

        //gestione click con elementi che hanno un listener esplicito del click
        if (elemClick) {
          let classList = elemClick.classList.value;
          classList =
            classList && classList !== ""
              ? `.${classList
                  .trim()
                  .split(" ")
                  .join(".")
                  .replace(".active", "")
                  .replace(".false", "")
                  .replace(".hide", "")
                  .replace(".autoplay.going", ".autoplay")
                  .replace(".cono-active", "")}`
              : null;
          let elemIndex = getElementClickIndexChild(elemClick);
          if (!holdActive) {
            let clickX = ev.clientX; // window.screen.width
            let clickY = ev.clientY; // window.screen.height
            socket.emit("click-event", {
              roomId: roomId.current,
              event: {
                x: clickX,
                y: clickY,
                screenWidth: window.screen.width,
                screenHeight: window.screen.height,
                classList,
                elemIndex,
              },
            });
          }
        }

        // Controllo per FLOORPLANNING
        // Ogni volta che il vendor modifica la quotazione personalizzata,
        // invio un evento al client via socket
        const parent = document.querySelector("div.quotation-sx"); // div.quotation-sx è il div padre del form della quotazione

        let clickDialogueButtonPreventivo = false;
        try {
          clickDialogueButtonPreventivo =
            ev.target &&
            ev.target.className &&
            ev.target.className.includes("button") &&
            ev.target.className.includes("primary-button") &&
            ev.target.parentElement &&
            ev.target.parentElement.className.includes("dialogue");
        } catch (e) {}

        if (
          ev.target &&
          parent &&
          parent !== ev.target &&
          (parent.contains(ev.target) || clickDialogueButtonPreventivo)
        ) {
          // La quotazione si trova nel localStorage customQuote
          socket.emit("quotation-event", {
            roomId: roomId.current,
            event: {
              quotation: JSON.parse(localStorage.getItem("store_floorplanning"))
                .customQuote,
              activePromos: JSON.parse(
                localStorage.getItem("store_floorplanning")
              ).activePromos,
              showRata: JSON.parse(localStorage.getItem("store_floorplanning"))
                .showRata,
            },
          });
        }
      }

      // GESTIONE VIDEO
      setTimeout(() => {
        let videos = document.querySelectorAll(
          "video:not(#local-video):not(#remote-video)"
        );
        if (videos && videos.length > 0) {
          if (!video && !videos[0].isEqualNode(video)) {
            video = videos[0];
            //removed timeupdate
            addListenerVideoMulti(
              video,
              "pause play seeked volumechange fullscreenchange webkitfullscreenchange mozfullscreenchange",
              function (e) {
                let videoEvent = e.type;
                socket.emit("video-event", {
                  roomId: roomId.current,
                  event: {
                    type: videoEvent,
                    volume: video.volume,
                    time: video.currentTime,
                  },
                });
              }
            );
          }
        }
      }, 1000);

      socket.on("videocall-event", (event) => {
        try {
          switch (event.name) {
            // Se il client non ha il fullscreen attivato..
            case FULLSCREEN_CLIENT_CHECK_EVENT:
              let userFullscreenStat = [...noFullscreenUsersRef.current];

              if (!event.isFullscreen) {
                userFullscreenStat.push(event.id);
              } else {
                userFullscreenStat = userFullscreenStat.filter(
                  (el) => el !== event.id
                );
              }

              userFullscreenStat = [...new Set(userFullscreenStat)];

              if (userFullscreenStat.length !== 0) {
                setVideochatInfoMessage(
                  `${
                    noFullscreenUsersRef.current.length > 1
                      ? "Multiple customers are not in fullscreen mode"
                      : "The client is not in fullscreen mode"
                  }`
                );
              } else {
                setVideochatInfoMessage("");
              }
              noFullscreenUsersRef.current = userFullscreenStat;
              break;

            default:
              break;
          }
        } catch (e) {
          console.error(e);
        }
      });
    });

    // Listening route change
    history.listen((location) => {
      currentLocationRef.current = location;
    });
  };

  const prepareClient = () => {
    // Se non è venditore imposto nel sessionStorage un fake vendorId
    // e il clientId reale passato nell'url

    Cookies.set("vendorId", "fake", cookieOptionsSet);

    const body = document.body;
    body.classList.add("i-am-the-client");

    // Gestione dei CLICK del CLIENTE remoto in VIDEOCHAT
    document.addEventListener(
      "click",
      (e) => {
        const elWithClickEnablerClass =
          e.target.closest(".videochat-client-mouse-enabled") ||
          e.target.closest(".click-always-enabled") ||
          e.target.closest(".drag-here");

        if (elWithClickEnablerClass) {
          return null;
        }
        if (!enableRemoteClickRef.current) {
          e.stopPropagation();
          e.preventDefault();
          return false;
        }
      },
      true
    );

    // CLICK
    socket.on("click-event", (event) => {
      if (
        event &&
        event.classList &&
        !event.classList.includes(".videochat-client-noclick") &&
        !event.classList.includes("videochat-no-remote-click")
      ) {
        try {
          let elToClick = document.querySelectorAll(event.classList);
          if (elToClick && elToClick.length > 1) {
            elToClick = document.querySelectorAll(event.classList)[
              event.elemIndex
            ];
          } else if (elToClick && elToClick.length === 1) {
            elToClick = document.querySelectorAll(event.classList)[0];
          }

          let rect = elToClick.getBoundingClientRect();
          let node = document.createElement("div");
          node.classList.add("dot");
          node.style.top = +rect.top + +rect.height / 2 - 10 + "px";
          node.style.left = +rect.left + +rect.width / 2 - 10 + "px";
          document.body.appendChild(node);
          setTimeout(() => {
            node.remove();
          }, 1000);

          // Aggiunto un timeout per il click per far vedere all'utente prima l'animazione
          // di dove si sta cliccando e poi fare l'operazione
          setTimeout(() => {
            enableRemoteClickRef.current = true;
            elToClick.click();
            enableRemoteClickRef.current = false;
          }, 200);
        } catch (e) {
          console.error(e.message);
        }
      }
    });

    // DRAG & DROP MOUSE
    let mouseMoving = false;
    let stopMouseMoving = null;
    let elMoving = null;

    socket.on("move-event", (event) => {
      if (event) {
        if (stopMouseMoving) clearTimeout(stopMouseMoving);
        //document.querySelector('.overlay-protector').style.display = 'none'
        let clientX,
          clientY,
          screenX,
          screenY,
          pageX,
          pageY,
          scaleX,
          scaleY = 0;
        scaleX = event.screenWidth / window.screen.width;
        scaleY = event.screenHeight / window.screen.height;
        scaleX = 1 / scaleX;
        scaleY = 1 / scaleY;

        clientX = event.clientX * scaleX;
        clientY = event.clientY * scaleY;

        screenX = event.screenX * scaleX;
        screenY = event.screenY * scaleY;

        pageX = event.pageX * scaleX;
        pageY = event.pageY * scaleY;

        let videoContainer = document.querySelector(".video-container");
        let elementFromRemote = null;
        let typeEvent = null;

        if (!elMoving) {
          let elementsFromRemote =
            document.elementsFromPoint(clientX, clientY) &&
            document.elementsFromPoint(clientX, clientY).length > 0
              ? document.elementsFromPoint(clientX, clientY)
              : null;

          if (elementsFromRemote) {
            for (let el of elementsFromRemote) {
              let eventsList = el.getEventListeners();
              if (
                eventsList.mousemove ||
                el.onmousemove ||
                el.mousemove ||
                eventsList.mousedown ||
                el.onmousedown ||
                el.mousedown
              ) {
                elementFromRemote = el;
                typeEvent = "click";
                break;
              }
            }
          }
        } else {
          elementFromRemote = elMoving;
        }

        if (elementFromRemote && typeEvent && videoContainer) {
          if (!mouseMoving) {
            let mousedownEvent = new MouseEvent("mousedown", {
              view: window,
              bubbles: true,
              //'composed': true,
              clientX,
              clientY,
              screenX,
              screenY,
              pageX,
              pageY,
              cancelable: true,
            });

            elementFromRemote.dispatchEvent(mousedownEvent);
            mouseMoving = true;
          } else {
            let mousemoveEvent = new MouseEvent("mousemove", {
              view: window,
              bubbles: true,
              composed: true,
              clientX,
              clientY,
              screenX,
              screenY,
              pageX,
              pageY,
              cancelable: true,
            });

            elementFromRemote.dispatchEvent(mousemoveEvent);
          }

          if (stopMouseMoving) clearTimeout(stopMouseMoving);
          if (event.type === "mouseup") {
            stopMouseMoving = setTimeout(() => {
              let mouseupEvent = new MouseEvent("mouseup", {
                view: window,
                bubbles: true,
                // 'composed': true,
                clientX,
                clientY,
                screenX,
                screenY,
                pageX,
                pageY,
                cancelable: true,
              });
              mouseMoving = false;
              elMoving = null;
              elementFromRemote.dispatchEvent(mouseupEvent);
            }, 100);
          }
        }

        let node = document.createElement("div");
        node.classList.add("dot");
        node.classList.add("dot-move");
        node.style.top = clientY + "px";
        node.style.left = clientX + "px";
        document.body.appendChild(node);
        setTimeout(() => {
          node.remove();
        }, 1000);
      }
    });

    //GESTIONE VIDEO
    socket.on("video-event", (event) => {
      let videos = document.querySelectorAll(
        "video:not(#local-video):not(#remote-video)"
      );
      if (videos && videos.length > 0) {
        let video = videos[0];
        switch (event.type) {
          case "pause":
            video.pause();
            break;
          case "play":
            video.play();
            break;
          case "seeked":
            video.currentTime = event.time;
            break;
          case "volumechange":
            video.volume = event.volume;
            break;
          case "fullscreenchange":
          case "webkitfullscreenchange":
          case "mozfullscreenchange":
            try {
              if (video.requestFullscreen) {
                video.requestFullscreen();
              } else if (video.mozRequestFullScreen) {
                /* Firefox */
                video.mozRequestFullScreen();
              } else if (video.webkitRequestFullscreen) {
                /* Chrome, Safari & Opera */
                video.webkitRequestFullscreen();
              } else if (video.msRequestFullscreen) {
                /* IE/Edge */
                video.msRequestFullscreen();
              }
            } catch (e) {
              console.error(e);
            }
            break;
          default:
            console.log("video event not handled");
        }
      }
    });

    // Ricevo e setto il JWT per il client per poter fare le chiamate che richiedono autorizzazione
    // in Floorplanning
    socket.on("receive-client-jwt", (event) => {
      try {
        const clientJWT = event.clientJWT;

        // Nuova logica per i cookie del cliente
        loginClientByVideocall(
          Client.LOGIN_CLIENT_BY_VIDEOCALL_OPTIONS(clientJWT)
        ).catch((e) => {
          console.error("receive-client-jwt error", e);
        });
      } catch (e) {
        console.error(e);
      }
    });

    socket.on("videocall-event", (event) => {
      try {
        switch (event.name) {
          case SCREEN_SHARING:
            // Arriva l'id del video da mettere in fullscreen, o vuoto se viene interrota la condivisione schermo
            setScreenSharingId(event.vendorPeerId);
            break;

          case FULLSCREEN_EVENT:
            setIsFullscreen(event.isFullscreen);
            break;

          case RESUME_VIDEOCALL:
            history.replace("/");
            setTimeout(() => {
              history.replace(event.pathname);
            }, 100);
            break;

          default:
            break;
        }
      } catch (e) {
        console.error(e);
      }
    });
  };

  const startCookieRefresh = (roomId, isVendor, clientId) => {
    updateCallCookie(roomId, isVendor, clientId);
    const interval = setInterval(
      () => updateCallCookie(roomId, isVendor, clientId),
      10 * 1000
    );
    return interval;
  };

  const stopCookieRefresh = () => {
    // Stoppo l'aggiornamento del cookie e lo cancello
    clearInterval(cookieRefresherRef.current);
    deleteCallCookie();
  };

  const startVideocall = () => {
    if (isVendor.current) {
      getClientToken();
      previousPeersLengthRef.current = peers.length;
    }

    // updateCallCookie(roomId.current, isVendor.current, clientId.current);
    setIsCallInProgress(true);

    // if (isVendor.current) {
    //   emitClientToken();
    // }

    // Riabilito le tracce per i clienti
    handleAudio(true);
    handleVideo(true);

    cookieRefresherRef.current = startCookieRefresh(
      roomId.current,
      isVendor.current,
      clientId.current
    );
  };

  const emitClientToken = () => {
    if (
      clientToken &&
      clientToken.called &&
      !clientToken.loading &&
      !clientToken.error
    ) {
      try {
        socket.emit("generate-client-jwt", {
          roomId: roomId.current,
          event: {
            clientJWT: clientToken.data.getClientToken.token,
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (isVendor.current) {
      emitClientToken();
    }
    // eslint-disable-next-line
  }, [clientToken]);

  useEffect(() => {
    // Controllo che il cliente in chiamata (non su dispositivo mobile),
    // abbia il dispositivo in fullscreen
    if (
      checkClientFullscreen.current &&
      !isVendor.current &&
      isCallInProgress
    ) {
      socket.emit("videocall-event", {
        roomId: roomId.current,
        event: {
          name: FULLSCREEN_CLIENT_CHECK_EVENT,
          isFullscreen: fs.fullScreen,
          id: socket.id,
        },
      });
    }

    //eslint-disable-next-line
  }, [fs]);

  useEffect(() => {
    if (isVendor.current && isCallInProgress) {
      if (peers.length > previousPeersLengthRef.current) {
        // Se è entrato un nuovo peer durante una chiamata
        // devo allineare tutte le schermate per poter mantenere il corretto
        // funzionamento dei click del vendor

        if (currentLocationRef.current?.pathname) {
          const pathname =
            currentLocationRef.current?.pathname === "/floorplanning"
              ? "/"
              : currentLocationRef.current?.pathname;

          socket.emit("videocall-event", {
            roomId: roomId.current,
            event: {
              name: RESUME_VIDEOCALL,
              pathname,
            },
          });
          history.replace("/");
          setTimeout(() => {
            history.replace(pathname);
          }, 100);
        } else {
          history.replace("/");
        }
      }

      previousPeersLengthRef.current = peers.length;
    }
    // eslint-disable-next-line
  }, [peers.length]);

  useEffect(() => {
    if (!isFullscreen && !isVendor.current) {
      try {
        setOtherVideosContainerPosition({ x: 0, y: 0 });
        draggableEntityRef.current.state.x = 0;
        draggableEntityRef.current.state.y = 0;
        setTimeout(() => {
          setOtherVideosContainerPosition(null);
        }, 100);
      } catch (error) {
        console.error(error);
      }
    }
  }, [isFullscreen]);

  const handleReconnectionInterval = () => {
    setReconnectionTimeout((el) => {
      if (el === 0) {
        clearInterval(reconnectionIntervalRef.current);

        startVideocall();

        return -1;
      }

      return el - 1;
    });
  };

  const videocallInit = async () => {
    // Prima di tutto inizializzo la webcam, così se non ho i permessi per accedere allo stream video
    // li ottengo prima di collegarmi alla stanza
    userVideo.current.srcObject = await getWebcamStream();

    // Muto tutte le tracce prima di cominciare la chiamata
    handleAudio();
    handleVideo();

    // Una volta preso lo stream della webcam, mi iscrivo alla stanza
    socket.emit("join-room", {
      roomId: roomId.current,
      isVendor: isVendor.current,
      vf: vfRef.current,
      vt: vtRef.current,
    });
  };

  const createPeer = (userToSignal, callerID, stream) => {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
      reconnectTimer: 100,
      iceTransportPolicy: "relay",
      config: {
        iceServers: [
          {
            urls: ["stun:turnstun.tecmasolutions.com:3478"],
          },
          {
            urls: ["turn:turnstun.tecmasolutions.com:3478"],
            credential: "8X-&NXg7T-J6fZGzk.M438P822NxXW52",
            username: "oracleTurnUser01",
          },
        ],
      },
    });

    peer.on("signal", (signal) => {
      socket.emit("sending-signal", {
        userToSignal,
        callerID,
        signal,
      });
    });

    return peer;
  };

  const addPeer = (incomingSignal, callerID, stream) => {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
      reconnectTimer: 100,
      iceTransportPolicy: "relay",
      config: {
        iceServers: [
          {
            urls: ["stun:turnstun.tecmasolutions.com:3478"],
          },
          {
            urls: ["turn:turnstun.tecmasolutions.com:3478"],
            credential: "8X-&NXg7T-J6fZGzk.M438P822NxXW52",
            username: "oracleTurnUser01",
          },
        ],
      },
    });

    peer.on("signal", (signal) => {
      socket.emit("returning-signal", { signal, callerID });
    });

    peer.signal(incomingSignal);

    return peer;
  };

  const handleAudio = (forceValue) => {
    if (userVideo.current.srcObject) {
      userVideo.current.srcObject.getTracks().forEach(function (track) {
        if (track.kind === "audio") {
          if (forceValue === true || forceValue === false) {
            if (forceValue) {
              enableAudio(track);
            } else {
              disableAudio(track);
            }
          } else {
            if (track.enabled) {
              disableAudio(track);
            } else if (!track.enabled || forceValue === true) {
              enableAudio(track);
            }
          }
        }
      });
    }
  };

  const enableAudio = (track) => {
    socket.emit("change", [
      ...userUpdate,
      {
        id: socket.id,
        videoFlag,
        audioFlag: true,
      },
    ]);
    track.enabled = true;
    setAudioFlag(true);
  };

  const disableAudio = (track) => {
    socket.emit("change", [
      ...userUpdate,
      {
        id: socket.id,
        videoFlag,
        audioFlag: false,
      },
    ]);
    track.enabled = false;
    setAudioFlag(false);
  };

  const handleVideo = (forceValue) => {
    if (userVideo.current.srcObject) {
      userVideo.current.srcObject.getTracks().forEach(function (track) {
        if (track.kind === "video") {
          if (forceValue === true || forceValue === false) {
            if (forceValue) {
              enableVideo(track);
            } else {
              disableVideo(track);
            }
          } else {
            if (track.enabled) {
              disableVideo(track);
            } else if (!track.enabled || forceValue === true) {
              enableVideo(track);
            }
          }
        }
      });
    }
  };

  const enableVideo = (track) => {
    socket.emit("change", [
      ...userUpdate,
      {
        id: socket.id,
        videoFlag: true,
        audioFlag,
      },
    ]);
    track.enabled = true;
    setVideoFlag(true);
  };

  const disableVideo = (track) => {
    socket.emit("change", [
      ...userUpdate,
      {
        id: socket.id,
        videoFlag: false,
        audioFlag,
      },
    ]);
    track.enabled = false;
    setVideoFlag(false);
  };

  const handleScreenSharing = async () => {
    if (peers) {
      if (isScreenSharing) {
        // Recupero lo stream della webcam
        let webcamStream = await getWebcamStream();

        // Lo faccio vedere nel riquadro del proprio video
        userVideo.current.srcObject = webcamStream;

        // prendo la traccia video dello stream
        webcamStream = webcamStream.getVideoTracks()[0];

        // La sostituisco a quella attuale a tutti i peer connessi
        peers.forEach((el) => {
          const { peer } = el;
          peer.replaceTrack(
            peer.streams[0].getVideoTracks()[0],
            webcamStream,
            peer.streams[0]
          );
        });

        try {
          const eventBody = {
            name: SCREEN_SHARING,
            vendorPeerId: "",
          };

          setScreenSharingId("");

          socket.emit("videocall-event", {
            roomId: roomId.current,
            event: eventBody,
          });
        } catch (error) {
          console.error(error);
        }

        setIsScreenSharing(false);
      } else {
        // Recupero lo stream dello schermo
        let screenStream = await getScreenStream();

        // Lo faccio vedere nel riquadro del proprio video
        userVideo.current.srcObject = screenStream;

        // Prendo la traccia video dello stream
        screenStream = screenStream.getVideoTracks()[0];

        // La sostituisco a quella attuale a tutti i peer connessi
        peers.forEach((el) => {
          const { peer } = el;
          peer.replaceTrack(
            peer.streams[0].getVideoTracks()[0],
            screenStream,
            peer.streams[0]
          );
        });

        try {
          const eventBody = {
            name: SCREEN_SHARING,
            vendorPeerId: socket.id,
          };

          setScreenSharingId(socket.id);

          socket.emit("videocall-event", {
            roomId: roomId.current,
            event: eventBody,
          });
        } catch (error) {
          console.error(error);
        }

        setIsScreenSharing(true);
      }
    }
  };

  const handleStartVideocall = () => {
    // Evento per far partire la chiamata ai clienti
    socket.emit("start-videocall");

    startVideocall();
  };

  const handleTerminateCall = async () => {
    setIsCallInProgress(false);

    handleAudio(false);
    handleVideo(false);

    if (isVendor.current) {
      // Se siamo il vendor, emetto un evento per far terminare la chiamata anche agli altri utenti
      socket.emit("terminate-videocall");
    }

    // Faccio comparire la dialog per far scegliere cosa fare dopo la chiamata
    setIsCallTerminated(true);

    // deleteCallCookie();
    stopCookieRefresh();
    socket.disconnect();
  };

  const handleAfterCallRedirect = (redirect) => {
    window.location.replace(redirect);
  };

  const handleFullscreen = () => {
    try {
      const newIsFullscreen = !isFullscreen;

      const eventBody = {
        name: FULLSCREEN_EVENT,
        isFullscreen: newIsFullscreen,
      };

      socket.emit("videocall-event", {
        roomId: roomId.current,
        event: eventBody,
      });
      setIsFullscreen(newIsFullscreen);
    } catch (e) {
      console.error("handleFullscreen", e);
    }
  };

  const handleResizeVideo = () => {
    setIsBigVideo(!isBigVideo);
  };

  return roomId.current ? (
    <>
      <div id="videochat-draggable-area" />
      <Grid
        className={clsx("user-video", "videochat-client-mouse-enabled")}
        alignItems="center"
        container
        direction="column"
      >
        {isVendor.current && (
          <>
            <Grow
              in={showOtherControls}
              {...(showOtherControls ? { timeout: 500 } : {})}
            >
              <Grid item xs="auto">
                <Tooltip title="Enable/Disable Fullscreen" placement="left">
                  <IconButton
                    size="small"
                    onClick={handleFullscreen}
                    className={clsx("videochat-no-remote-click")}
                  >
                    {isFullscreen ? (
                      <FullscreenExitIcon fontSize="small" />
                    ) : (
                      <FullscreenIcon fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grow>
            <Grow in={showOtherControls}>
              <Grid item xs="auto">
                <Tooltip title="Share Screen" placement="left">
                  <IconButton
                    size="small"
                    onClick={handleScreenSharing}
                    className={clsx(
                      isScreenSharing && "screen-sharing-active",
                      "videochat-no-remote-click"
                    )}
                  >
                    <ScreenShareOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grow>
            <Grid item xs="auto">
              <Tooltip title="Show/hide other commands" placement="left">
                <IconButton
                  size="small"
                  onClick={() => setShowOtherControls((el) => !el)}
                  className={clsx(
                    showOtherControls && "expanded",
                    "show-other-controls"
                  )}
                >
                  <MoreHorizIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        )}

        <Grid item xs="auto">
          <Tooltip title="Activate/Deactivate Audio" placement="left">
            <IconButton
              size="small"
              onClick={handleAudio}
              className={clsx("videochat-no-remote-click")}
            >
              {audioFlag ? (
                <MicIcon fontSize="small" />
              ) : (
                <MicOffIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs="auto">
          <Tooltip title="Activate/Deactivate Video" placement="left">
            <IconButton
              size="small"
              onClick={handleVideo}
              className={clsx("videochat-no-remote-click")}
            >
              {videoFlag ? (
                <VideocamIcon fontSize="small" />
              ) : (
                <VideocamOffIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs="auto">
          <Tooltip title="End call" placement="left">
            <IconButton
              size="small"
              onClick={handleTerminateCall}
              className={clsx("videochat-no-remote-click")}
            >
              <CallEndIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs="auto">
          <video muted ref={userVideo} autoPlay playsInline />
        </Grid>
      </Grid>

      <Draggable
        position={otherVideosContainerPosition}
        bounds="#videochat-draggable-area"
        onStart={() => setIsDragging(true)}
        onStop={() => setIsDragging(false)}
        disabled={screenSharingId || isFullscreen}
        ref={draggableEntityRef}
      >
        <Grid
          container
          className={clsx(
            "other-videos-container",
            (!isCallInProgress || isCallTerminated || isScreenSharing) &&
              "hide",
            isDragging && "is-dragging",
            screenSharingId && "screen-sharing",
            (screenSharingId || isFullscreen) && "fullscreen",
            isBigVideo && "big-video",
            peers?.length >= 3 && !screenSharingId && "half-height",
            "videochat-client-mouse-enabled"
          )}
        >
          {peers.map((peer) => {
            return (
              <Grid
                xs
                item
                key={peer.peerId}
                className={clsx(
                  peer.peerId === screenSharingId && "screen-sharing-video"
                )}
              >
                <Video peer={peer} />
              </Grid>
            );
          })}

          <Tooltip title="Resize video" placement="left">
            <IconButton
              size="small"
              onClick={handleResizeVideo}
              className={clsx(
                "videocall-resize-button",
                "videochat-no-remote-click",
                "videochat-client-mouse-enabled"
              )}
            >
              <PhotoSizeSelectSmallIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Draggable>

      {/* Modale stanza vuota VENDOR */}
      <VideocallDialog
        isOpen={isVendor.current && peers.length === 0 && !isCallInProgress}
        title={"No customers in the room..."}
      >
        No customers are in the room yet. Wait for someone to enter before you
        can start the video call...
      </VideocallDialog>

      {/* Modale stanza vuota VENDOR */}
      <VideocallDialog
        isOpen={isVendor.current && peers.length === 0 && isCallInProgress}
        title={"No customers in the room..."}
      >
        No more customers are connected. Wait for someone to come back...
      </VideocallDialog>

      <VideocallDialog
        isOpen={isVendor.current && peers.length > 0 && !isCallInProgress}
        title={"Do you want to start the call?"}
        handleSubmit={handleStartVideocall}
      >
        {peers.length === 1
          ? "There is a customer"
          : `There are ${peers.length} customers`}{" "}
        in the room. Do you want to start the call?
      </VideocallDialog>

      {/* Modale attesa avvio chiamata CLIENT */}
      <VideocallDialog
        isOpen={!isVendor.current && !isCallInProgress && !isCallTerminated}
        title={"Wait..."}
      >
        You are inside the room. Wait for the sales representative to start the
        video call...
      </VideocallDialog>
      {/* Modale di fine chiamata */}
      <VideocallDialog
        isOpen={isCallTerminated && !isVendorDown}
        title={"Call ended"}
        handleCancel={() => {
          handleAfterCallRedirect(videocallUrlRef.current);
        }}
        handleCancelLabel="Call again"
        handleSubmit={() => {
          handleAfterCallRedirect(
            store.projectUrl ? store.projectUrl : `https://${store.hostKey}`
          );
        }}
        handleSubmitLabel="Back to Home"
      >
        The call ended, do you want to start it again or return to Home?
      </VideocallDialog>
      {/* Modale per il cliente se si disconnette il vendor */}
      <VideocallDialog
        isOpen={!isVendor.current && isVendorDown}
        title={"Vendor disconnected"}
        handleSubmit={() => {
          handleAfterCallRedirect(videocallUrlRef.current);
        }}
        handleSubmitLabel="Back to the beginning of the call"
      >
        The vendor disconnected due to connection problems. Go back to the
        beginning of the call and wait to be called back.
      </VideocallDialog>
      {/* Dialog di ricollegamento ad una chiamata già in corso */}
      <VideocallDialog
        isOpen={reconnectionTimeout > 0}
        title={"Connection in progress..."}
      >
        In {reconnectionTimeout} seconds you will automatically be connected to
        the call.
      </VideocallDialog>
      {/* Dialog di ricollegamento ad una chiamata già in corso */}
      <VideocallDialog
        isOpen={showInvalidLinkError}
        title={"Invalid Videocall link!"}
        handleSubmit={() => {
          handleAfterCallRedirect(
            isVendor.current
              ? getToolLink(store.enabledTools, "businessplatform")
              : store.projectUrl
              ? store.projectUrl
              : `https://${store.hostKey}`
          );
        }}
        handleSubmitLabel={
          isVendor.current
            ? "Back to Business Platform"
            : `Go to ${store.projectUrl ? store.projectUrl : store.hostKey}`
        }
      >
        The link you used to access the videocall is not yet active, has expired
        or is invalid.
      </VideocallDialog>
      {isVendor.current && (
        <Snackbar
          className={clsx("videocall-vendor-snackbar")}
          open={videochatInfoMessage ? true : false}
          TransitionComponent={Fade}
          message={videochatInfoMessage}
        />
      )}
    </>
  ) : (
    ""
  );
};

export default Videocall;
