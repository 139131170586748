export const getWebcamStream = async () => {
  const webcamStreamOptions = {
    audio: true,
    video: {
      width: { ideal: 640 },
      height: { ideal: 360 },
    },
  };

  const webcamStream = await navigator.mediaDevices.getUserMedia(webcamStreamOptions);

  return webcamStream;
};

export const getScreenStream = async () => {
  const screenStream = await navigator.mediaDevices.getDisplayMedia();

  return screenStream;
};

export const getElementClick = (el) => {
  let eventsList = el.getEventListeners();
  if (el.onclick || eventsList.click) {
    if (document) return el;
  } else {
    if (el.parentElement) return getElementClick(el.parentElement);
    else return null;
  }
};

export const getElementClickIndexChild = (child) => {
  let i = 0;
  while (child.previousElementSibling && child.previousElementSibling.classList.value === child.classList.value) {
    child = child.previousElementSibling;
    i++;
  }

  return i;
};

export const addListenerVideoMulti = (el, s, fn) => {
  s.split(' ').forEach((e) => el.addEventListener(e, fn, false));
};
