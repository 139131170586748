export const SYNC_SIZE = 'sync-size';
export const FULLSCREEN_EVENT = 'fullscreen-event';
export const FULLSCREEN_CLIENT_CHECK_EVENT = 'fullscreen-client-check-event';
export const VIDEO_PAUSE_EVENT = 'video-pause-event';
export const VIDEO_PLAY_EVENT = 'video-play-event';
export const VIDEO_SEEKING_EVENT = 'video-seeking-event';
export const VIRTUAL_TOUR_CHANGE_CONTROL = 'virtual-tour-change-control';
export const VIDEO_SPEED_CHANGE_EVENT = 'video-speed-change-event';
export const SCREEN_SHARING = 'screen-sharing';
export const RESUME_VIDEOCALL = 'resume-videocall';
