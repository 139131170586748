import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const API = (store) => {

  const httpLink = from([
    new HttpLink({
      credentials: "include",
      uri: process.env.REACT_APP_APOLLO_URI,
    }),
  ]);

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers
      },
    };
  });

  return new ApolloClient({
    credentials: "include",
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({}),
  });
};

export default API;
