import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../store/storeUtils';
import CMSNeurosalesWrapper from 'cms/dist/components/neurosales/CMSNeurosalesWrapper';
import { toJS } from 'mobx';
import { reloadGeneratedCustomCSS, reloadSettingsCSS } from '../../utils/DOMInjector';
import { ALL_FONTS } from '../../constants/fonts';
import { useHistory } from 'react-router-dom';

const CMSContainer = observer((props) => {
  const store = useStore();
  const history = useHistory();

  return (
    <>
      <CMSNeurosalesWrapper
        forceUpdate={props.forceUpdate}
        cmsModal={store.cmsModal}
        setCmsModal={store.setCmsModal}
        projectInfo={{
          projectName: store.projectName,
          displayName: store.projectTitle,
          id: store.projectId,
          hostKey: store.hostKey,
          payoff: store.projectDescription,
        }}
        page={store.currentPage}
        path={store.currentPath}
        previewConfiguration={store.previewConfiguration}
        setPreviewConfiguration={store.setPreviewConfiguration}
        selectTile={store.selectPageTiles}
        setSelectPageTiles={store.setSelectPageTiles}
        renderSettings={() => {
          reloadSettingsCSS(store.getSettings());
        }}
        renderCustomCss={() => {
          reloadGeneratedCustomCSS(store.getCustomCss());
        }}
        settings={store.cmsSettings}
        setSettingsPage={store.setCmsSettingsPage}
        modifyPageTiles={store.modifyPageTiles}
        setModifyPageTiles={store.setModifyPageTiles}
        defaultConfiguration={toJS(store.defaultPreviewConfiguration)}
        firstStart={store.firstStart}
        setFirstStart={(firstStart) => store.setFirstStart(firstStart)}
        allFonts={ALL_FONTS}
        history={history}
        baseUrl={store.baseUrl}
      />
    </>
  );
});

export default CMSContainer;
