import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import React from 'react';

const VideocallDialog = ({ title, handleClose, isOpen, children, handleCancel, handleCancelLabel = 'Annulla', handleSubmit, handleSubmitLabel = 'Ok' }) => {
  return (
    <Dialog classes={{ root: 'videocall-dialog videochat-client-mouse-enabled' }} onClose={handleClose} open={isOpen} maxWidth={'xs'}>
      <DialogTitle>{title}</DialogTitle>
      <div className='videocall-dialog-message' padding='0 20px 10px' textAlign={'center'}>
        {children}
      </div>

      <DialogActions>
        {handleCancel && (
          <Button onClick={handleCancel} color='primary'>
            {handleCancelLabel}
          </Button>
        )}
        {handleSubmit && (
          <Button onClick={handleSubmit} color='primary' autoFocus>
            {handleSubmitLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default VideocallDialog;
