import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useStore } from '../store/storeUtils';

const Navbar = () => {
  const [toggleState, setToggleState] = useState('on');
  const toggleScreen = () => setToggleState(toggleState === 'off' ? 'on' : 'off');
  let history = useHistory();

  const store = useStore();

  /** TODO: try to use double tap**/
  // 	const bind = useDoubleTap((event) => {
  // 	    event.preventDefault()
  // 					setToggleState(toggleState === "opaque" ? "on" : "opaque")
  //   });

  return (
    <>
      <div className='navbar primaryBackgroundColor' id='navbar'>
        <div className={`black-overlay ${toggleState}`} data-testid={'navbar-nolock'} />
        <div className='navIconWrapper spacing'>
          <div
            className='navIcons back'
            onClick={() => history.goBack()}
            style={{
              backgroundImage: `url('${process.env.REACT_APP_BUCKET_BASEURL}/global/img/icon/back.svg')`,
            }}
            data-testid={'navbar-back'}
          />
          {!store.disableNoTap ? (
            <div
              className='navIcons no-tap'
              onClick={() => toggleScreen()}
              style={{
                backgroundImage: `url('${process.env.REACT_APP_BUCKET_BASEURL}/global/img/icon/ico-no-tap.svg')`,
              }}
              data-testid={'navbar-lock'}
            />
          ) : null}
          <div
            className='navIcons refresh'
            onClick={() => window.location.reload()}
            style={{
              backgroundImage: `url('${process.env.REACT_APP_BUCKET_BASEURL}/global/img/icon/ico-refresh.svg')`,
            }}
            data-testid={'navbar-refresh'}
          />
          {store.getSettings() &&
          store.getSettings().design &&
          store.getSettings().design.disambiguationUrl &&
          store.getSettings().design.disambiguationUrl !== '' &&
          store.getSettings().design.disambiguationIcon &&
          store.getSettings().design.disambiguationIcon !== '' ? (
            // eslint-disable-next-line
            <a className='navIcons disambiguationUrl ext-link' href={store.getSettings().design.disambiguationUrl} />
          ) : null}
        </div>

        {store.isNavbarLink ? (
          <NavLink
            data-testid={'navbar-home'}
            activeClassName='active'
            className='logo'
            to='/'
            style={{ backgroundImage: `url(${process.env.REACT_APP_BUCKET_BASEURL}${store.getSmallLogoNavbar()})` }}
          />
        ) : (
          <div className='logo' style={{ backgroundImage: `url(${process.env.REACT_APP_BUCKET_BASEURL}${store.getSmallLogoNavbar()})` }}></div>
        )}

        <div className='navIconWrapper spacing'>
          <div className='navIcons hidden' />
          <div className='navIcons hidden' />
          {/* <NavLink activeClassName="active" className="navIcons facebook" to="/" /> */}
        </div>
      </div>
    </>
  );
};

export default Navbar;
