// RECURSIVE FUNCTION TO GET LIST ITEMS FROM CONFIG DB
export const GetTargetList = (config, target, urlParams) =>
{
  if(urlParams[target[0]] && target.length>1)
  {
    for(let i=0; i<config[target[0]].length; i++)
      if(config[target[0]][i].name===urlParams[target[0]])
        return GetTargetList(config[target[0]][i], target.splice(1,target.length), urlParams)
  }
  else
  {
    let objectList = [];
    for(let i=0; i<config[target].length; i++)
      objectList.push(config[target][i])
    return objectList;
  }
}

export const isCmsEnable = (user) => {
  return process.env.REACT_APP_CMS_ENABLE === "true" && user && (user.role === "admin" || user.role ==="configuration_manager");
}

export const isLocalhost = () => {
  return process.env.REACT_APP_LOCALHOST_BYPASS === "true" && window.location.hostname.indexOf('localhost') === -1;
}

// RECURSIVE FUNCTION TO GET OBJECT FROM CONFIG DB
export const GetTargetObject = (config, target, urlParams) =>
{
  if(urlParams[target[0]] && target.length>1)
  {
    for(let i=0; i<config[target[0]].length; i++)
      if(config[target[0]][i].name===urlParams[target[0]])
        return GetTargetObject(config[target[0]][i], target.splice(1,target.length), urlParams)
  }
  else
  {
    return config;
  }
}



// RECURSIVE FUNCTION TO GET OBJECT FROM CONFIG DB WITH TARGET AND NAME
export const GetObjectByName = (config, target, name) =>
{
  var targets = (typeof(target) == "string") ? target.split("/") : target;

  if(targets.length === 0)
  {
    if(config.name === name)
      return config;
  }
  else
  {
    for(let i=0; i<config[targets[0]].length; i++)
    {
      const success = GetObjectByName(config[targets[0]][i], targets.slice(1,targets.length), name)
      if(success)
        return success;
    }

  }
}



export const GetListFromObject = (config, target) =>
{
  let objectList = [];
  for(let i=0; i<config[target].length; i++)
    objectList.push(config[target][i])
  return objectList;
}



export const ConvertUrlParamsToString = (urlParams) =>
{
  const keys = Object.keys(urlParams)
  for (let i=0; i<keys.length; i++)
    urlParams[keys[i]]=urlParams[keys[i]].replaceAll('-',' ');
  return urlParams;
}

export const ConvertPathToCssClass = (path) => {
  // rimozione "/" finali (il browser potrebbe aggiungere slash finali)
  const trimmedPath = path.endsWith("/") ? path.slice(0, -1) : path;
  // replace / del percorso in ---
  return trimmedPath.replace(/\//g, '---');
}

export const ConvertStringToValidCssClass = (string) => {
  // replace / del percorso in ---
  return string.replace(/ /g, '-').replace(/\//g, '-').toLowerCase();
}