import React, { useEffect, useState } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import * as serviceWorker from '../../serviceWorker';
//import * as serviceWorker from '../../serviceWorker_locale';
import getParameterByName from '../../videochat/utils/getParameterByName';
import Loader from '../Loader';

const ServiceWorkerWrapper = ({ baseUrl = '/' }) => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);
  const [showReloading, setShowReloading] = React.useState(false);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.triggerSwReadyEvent();

    const roomId = getParameterByName('roomId');
    if (roomId) {
      serviceWorker.unregister();
    } else {
      serviceWorker.register({ onUpdate: onSWUpdate }, baseUrl);
    }
  }, []);

  const reloadPage = () => {
    delete localStorage['store'];
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      waitingWorker.postMessage('SKIP_WAITING');
      setShowReloading(true);
    }
  };

  return (
    <Snackbar
      open={showReload}
      message={
        !showReloading ? (
          'Una nuova versione è disponibile!'
        ) : (
          <>
            <div style={{ height: '60px' }}></div>
          </>
        )
      }
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{ zIndex: '9999999999999999' }}
      action={
        showReloading ? (
          <Loader loading={true} hasBackdrop={false} transparency />
        ) : (
          <Button color='inherit' size='small' onClick={reloadPage}>
            Aggiorna
          </Button>
        )
      }
    />
  );
};

export default ServiceWorkerWrapper;
