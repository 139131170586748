import { APP_ASSETS } from "../constants";
import { ALL_FONTS } from "./../constants/fonts";

export const modifyTitle = (title) => {
  document.title = title;
};

export const modifyDescription = (description) => {
  var meta = document.getElementsByTagName("meta");
  for (var i = 0; i < meta.length; i++) {
    if (meta[i].name.toLowerCase() === "description") {
      meta[i].content = description;
    }
  }
};

export const loadCss = (assetFolder) => {
  let versionUpdate = new Date().getTime();
  if (process.env.REACT_APP_CSS_BASEURL) {
    loadHTMLElement(
      `${process.env.REACT_APP_CSS_BASEURL}/${encodeURI(
        assetFolder
      )}/tecma-bp-style.css`,
      "css"
    );
  } else {
    loadHTMLElement(
      `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
        assetFolder
      )}/neurosales/css/tecma-bp-style.css?v=${versionUpdate}`,
      "css"
    );
  }
};

const generateCssSettings = (settings) => {
  let buttonRadius = "0px";
  let sliderButtonRadius = "0px";
  if (settings && settings.design && settings.design.buttonType) {
    switch (settings.design.buttonType) {
      case "squared":
        buttonRadius = "0px";
        sliderButtonRadius = "0px";
        break;
      case "round50":
        buttonRadius = "4px";
        sliderButtonRadius = "4px";
        break;
      case "round100":
        buttonRadius = "20px";
        sliderButtonRadius = "100%";
        break;
      default:
        break;
    }
  }

  const primaryFont =
    settings && settings.fonts
      ? ALL_FONTS.find((font) => font.name === settings.fonts.primaryFont)
      : null;
  const secondaryFont =
    settings && settings.fonts
      ? ALL_FONTS.find((font) => font.name === settings.fonts.secondaryFont)
      : null;

  return `   
            .white-loader {
                display: none!important;
            }

            ${
              primaryFont
                ? primaryFont.types.map((type) => {
                    return `
                        @font-face {
                            font-family: "primary-font";
                            src: url(${process.env.REACT_APP_BUCKET_BASEURL}${
                      type.path
                    });

                            ${
                              type.style && type.style.fontStyle
                                ? `font-style: ${type.style.fontStyle};`
                                : ""
                            }

                            ${
                              type.style && type.style.fontWeight
                                ? `font-weight: ${type.style.fontWeight};`
                                : ""
                            }
                        }

                        body, html {
                            font-family: primary-font!important;
                        }
                    `;
                  })
                : ""
            }

            ${
              secondaryFont
                ? secondaryFont.types.map((type) => {
                    return `
                        @font-face {
                            font-family: "secondary-font";
                            src: url("${process.env.REACT_APP_BUCKET_BASEURL}${
                      type.path
                    }")

                            ${
                              type.style && type.style.fontStyle
                                ? `font-style: ${type.style.fontStyle} `
                                : ""
                            }

                            ${
                              type.style && type.style.fontWeight
                                ? `font-weight: ${type.style.fontWeight} `
                                : ""
                            }
                        }
                    `;
                  })
                : ""
            }

            ${
              settings.colors.primaryColor
                ? `
                    .primaryBackgroundColor {
                        background-color: ${settings.colors.primaryColor}!important
                    }
                    `
                : ""
            }

            ${
              settings.colors.secondaryColor
                ? `
                    .secondaryBackgroundColor {
                        background-color: ${settings.colors.secondaryColor}!important
                    }
                    `
                : ""
            }

            ${
              settings.colors.backgroundColor
                ? `
                    .standardBackground {
                        background-color: ${settings.colors.backgroundColor}!important
                    }
                    `
                : ""
            }

            ${
              settings.colors.textColor
                ? `
                    .standardTextColor {
                        color: ${settings.colors.textColor}!important
                    }
                    `
                : ""
            }

            ${
              settings.colors.textColorButton
                ? `
                    .buttonTextColor {
                        color: ${settings.colors.textColorButton}!important
                    }
                    `
                : ""
            }

            ${
              settings.colors.textColorButton && settings.colors.primaryColor
                ? `
                    .primaryButton {
                        background-color: ${settings.colors.primaryColor}!important;
                        color: ${settings.colors.textColorButton}!important;
                        border-radius: ${buttonRadius}!important;
                    }
                    `
                : ""
            }

            ${
              settings.colors.textColorButton && settings.colors.secondaryColor
                ? `
                    .secondaryButton {
                        background-color: ${settings.colors.secondaryColor}!important;
                        color: ${settings.colors.textColorButton}!important;
                        border-radius: ${buttonRadius}!important;
                    }
                    `
                : ""
            }

            ${
              settings.colors.primaryColor && settings.colors.secondaryColor
                ? `
                    .SliderButton.primaryButton, .SliderButton.secondaryButton {
                        border-radius: ${sliderButtonRadius}!important;
                    }
                    `
                : ""
            }
            ${
              settings &&
              settings.design &&
              settings.design.disambiguationUrl &&
              settings.design.disambiguationUrl !== "" &&
              settings.design.disambiguationIcon &&
              settings.design.disambiguationIcon !== ""
                ? `.disambiguationUrl {
                background-image:url('${
                  process.env.REACT_APP_BUCKET_BASEURL +
                  settings.design.disambiguationIcon
                }');
                background-position:center;
                background-repeat:no-repeat;
                background-size:auto;
            }`
                : ".disambiguationUrl{display:none}"
            }
        `;
};

const generateCustomCss = (css) => {
  return `
        ${Object.keys(css)
          .map((key) => {
            // caso regole css scritte a mano
            if (typeof css[key] === "string") {
              return `${key} {${css[key]}}`;
            } else {
              return `
                    *[data-custom-css-id="${key}"] {
                        ${Object.keys(css[key])
                          .map((cssProp) => {
                            return `${cssProp}: ${css[key][cssProp]}!important;`;
                          })
                          .join("")}
                    }
                `;
            }
          })
          .join("")}    
    `;
};

export const loadCustomCss = (cssObj) => {
  let fileref = document.createElement("style");
  fileref.setAttribute("id", "cms-custom-css-generated");
  fileref.setAttribute("type", "text/css");

  if (cssObj) {
    const css = generateCustomCss(cssObj);

    fileref.appendChild(document.createTextNode(css));
  }

  document.getElementsByTagName("head")[0].appendChild(fileref);
};

export const loadSettingsCSS = (settings) => {
  let fileref = document.createElement("style");
  fileref.setAttribute("id", "cms-custom-css");
  fileref.setAttribute("type", "text/css");

  if (settings) {
    const css = generateCssSettings(settings);

    fileref.appendChild(document.createTextNode(css));
  }

  document.getElementsByTagName("head")[0].appendChild(fileref);
};

export const reloadSettingsCSS = (settings) => {
  const css = generateCssSettings(settings);
  let fileRef = document.getElementById("cms-custom-css");

  // remove all childs
  while (fileRef.firstChild) {
    fileRef.firstChild.remove();
  }

  fileRef.appendChild(document.createTextNode(css));
};

export const reloadGeneratedCustomCSS = (cssObj) => {
  const css = generateCustomCss(cssObj);
  let fileRef = document.getElementById("cms-custom-css-generated");

  // remove all childs
  if (fileRef) {
    while (fileRef.firstChild) {
      fileRef.firstChild.remove();
    }
  }

  fileRef.appendChild(document.createTextNode(css));
};

export const loadFavicon = (assetFolder) => {
  loadHTMLElement(
    `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
      assetFolder
    )}/global/img/favicon.ico`,
    "icon"
  );
  loadHTMLElement(
    `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
      assetFolder
    )}/global/img/logo192.png`,
    "apple-touch-icon"
  );
};

export const generateManifest = (config) => {
  if (config) {
    try {
      const manifestData = {
        name: config.name,
        short_name: config.shortName,
        icons: config.icons,
        start_url:
          window.location.protocol + "//" + window.location.hostname + "/",
        orientation: config.orientation,
        display: config.display,
        theme_color: config.themeColor,
        background_color: config.backgroundColor,
      };

      const stringManifest = JSON.stringify(manifestData);
      const blob = new Blob([stringManifest], { type: "application/json" });
      const manifestURL = URL.createObjectURL(blob);
      document
        .querySelector("#application-manifest")
        .setAttribute("href", manifestURL);
    } catch (e) {
      console.error(e);
    }
  }
};

export const preLoadAssets = async (
  neurosalesConfig,
  setNumAssets,
  setNumAssetsLoaded,
  setNumAssetsError
) => {
  let numAssetsLoaded = 0;
  let numAssetError = 0;

  const preloadAsset = ({ url, type }) =>
    new Promise(() => {
      if (type === "img") {
        const image = new Image();
        image.onload = () => {
          setNumAssetsLoaded(++numAssetsLoaded);
          if (numAssetsLoaded + numAssetError === APP_ASSETS.length) {
            finishPreload();
          }
        };
        image.onerror = () => {
          console.error("Error on preload asset ", url);
          setNumAssetsError(++numAssetError);
          if (numAssetsLoaded + numAssetError === APP_ASSETS.length) {
            finishPreload();
          }
        };
        image.crossOrigin = "Anonymous";
        image.src = url;
      } else if (type === "video") {
        const req = new XMLHttpRequest();
        req.open("GET", url, true);
        req.responseType = "blob";
        req.onload = function () {
          if (this.status === 200) {
            setNumAssetsLoaded(++numAssetsLoaded);
          } else {
            console.error("onloead Error on preload asset ", url);
            setNumAssetsError(++numAssetError);
          }
          if (numAssetsLoaded + numAssetError === APP_ASSETS.length) {
            finishPreload();
          }
        };
        req.onerror = function () {
          console.error("Error on preload asset ", url);
          setNumAssetsError(++numAssetError);
          if (numAssetsLoaded + numAssetError === APP_ASSETS.length) {
            finishPreload();
          }
        };
        req.send();
      }
    });

  if (neurosalesConfig) {
    traverseSearch(neurosalesConfig, APP_ASSETS);
    setNumAssets(APP_ASSETS.length);
  }

  await Promise.all(APP_ASSETS.map(preloadAsset));
};

function finishPreload() {
  caches.open("preloadCached");
}

function loadHTMLElement(filename, filetype) {
  let fileref;
  if (filetype === "js") {
    fileref = document.createElement("script");
    fileref.setAttribute("type", "text/javascript");
    fileref.setAttribute("src", filename);
  } else if (filetype === "css") {
    fileref = document.createElement("link");
    fileref.setAttribute("rel", "stylesheet");
    fileref.setAttribute("type", "text/css");
    fileref.setAttribute("href", filename);
    fileref.setAttribute("id", "css-neurosales");
  } else if (filetype.includes("icon")) {
    fileref = document.createElement("link");
    fileref.setAttribute("rel", filetype);
    fileref.setAttribute("href", filename);
  } else if (filetype === "manifest") {
    fileref = document.createElement("link");
    fileref.setAttribute("rel", "manifest");
    fileref.setAttribute("href", filename);
  }
  if (typeof fileref !== "undefined") {
    document.getElementsByTagName("head")[0].appendChild(fileref);
  }
}

function traverseSearch(obj, assets) {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (
      value &&
      typeof value === "string" &&
      (value.toLowerCase().includes(".jpg") ||
        value.toLowerCase().includes(".png") ||
        value.toLowerCase().includes(".svg") ||
        value.toLowerCase().includes(".jpeg"))
    ) {
      assets.push({
        url: process.env.REACT_APP_BUCKET_BASEURL + value,
        type: "img",
      });
    } else if (
      value &&
      typeof value === "string" &&
      value.toLowerCase().includes(".mp4")
    ) {
      assets.push({
        url: process.env.REACT_APP_BUCKET_BASEURL + value,
        type: "video",
      });
    } else if (value && typeof value === "object") {
      traverseSearch(value, assets);
    }
  });
}
