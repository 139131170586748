/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useStore } from '../store/storeUtils.jsx';
import { useLazyQuery } from '@apollo/client';
// import { useMutation } from '@apollo/client';
import Client from '../client/Client';
import { useHistory } from 'react-router-dom';

const JwtChecker = () => {
  const store = useStore();
  const history = useHistory();

  const [loadUserInfo, userInfo] = useLazyQuery(Client.GET_USER_INFO, Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId));

  const logout = () => {
    store.setLoggedUser(null);
    if (!store.skipLogin) history.push('/login');
  };

  useEffect(() => {
    if (userInfo && !userInfo.loading && userInfo.called) {
      if (userInfo.data) store.setLoggedUser(userInfo.data.getUserByJWT);
      if (userInfo.error || userInfo.errors) logout();
    }
  }, [userInfo, !userInfo.loading, userInfo.called, userInfo.data, userInfo.error]);

  function handleVisibilityChange() {
    if (!document.hidden && !store.componentMode) {
      loadUserInfo();
    }
  }

  React.useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () => window.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  return <></>;
};

export default JwtChecker;
