/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../store/storeUtils';
import NeurosalesTopBar from 'cms/dist/components/neurosales/components/common/NeurosalesTopBar';
import { useMutation, useQuery } from '@apollo/client';
import Client from '../../client/Client';
import Snackbar from 'cms/dist/components/generic/inputs/simpleComponents/Snackbar';
import { useHistory } from 'react-router-dom';
import { isCmsEnable } from '../../utils/utils';

const CMSTopBarContainer = observer((props) => {
  const store = useStore();
  const snackbarRef = useRef(null);
  const snackbarErrorRef = useRef(null);
  const snackbarWarningRef = useRef(null);
  const history = useHistory();

  const [publishPreviewConfiguration] = useMutation(Client.PUBLISH_PREVIEW_CONFIGURATION_MUTATION);
  const [logoutMutation] = useMutation(Client.LOGOUT);
  const products = useQuery(Client.GET_ALL_PRODUCTS, Client.GET_ALL_PRODUCTS_DEFAULT_OPTIONS(store.projectId));
  const projectToolStatus = useQuery(Client.GET_PROJECT_TOOL_STATUS, Client.GET_PROJECT_TOOL_STATUS_OPTIONS(store.projectId));

  const publishConfiguration = () => {
    const configuration = store.previewConfiguration;
    if (!configuration) {
      snackbarWarningRef.current.showSnackbar('Non sono state create nuove configurazioni da pubblicare');
    } else {
      publishPreviewConfiguration(Client.PUBLISH_PREVIEW_CONFIGURATION_OPTIONS(store.projectId, { ...configuration, tool: 'Emotions' }))
        .then((e) => {
          snackbarRef.current.showSnackbar('Le modifiche sono state pubblicate correttamente.');
        })
        .catch((e) => {
          snackbarErrorRef.current.showSnackbar('La pubblicazione della configurazione non è andato a buon fine.');
        });
    }
  };

  useEffect(() => {
    if (products && !products.loading && products.called && products.data && products.data.getAllProducts) {
      if (projectToolStatus && !projectToolStatus.loading && projectToolStatus.called && projectToolStatus.data && projectToolStatus.data.getProjectToolStatus) {
        store.setProducts(products.data.getAllProducts, projectToolStatus.data.getProjectToolStatus);
      } else {
        store.setProducts(products.data.getAllProducts);
      }
    }
  }, [products, projectToolStatus]);

  return (
    <>
      {isCmsEnable(store.loggedUser) ? (
        <NeurosalesTopBar
          logo={store.getSmallLogoNavbar()}
          setCmsModal={store.setCmsModal}
          selectPageTiles={store.selectPageTiles}
          setSelectPageTiles={store.setSelectPageTiles}
          setModifyPageTiles={store.setModifyPageTiles}
          firstStart={store.firstStart}
          settingsPage={store.cmsSettings}
          setSettingsPage={store.setCmsSettingsPage}
          projectId={store.projectId}
          loggedUser={store.loggedUser}
          history={history}
          onSave={() => {
            publishConfiguration();
          }}
          currentPage={store.currentPage}
          logout={logoutMutation}
          baseUrl={store.baseUrl}
          products={store.products}
          enabledTools={store.enabledTools}
        />
      ) : null}

      <Snackbar ref={snackbarRef} icon='check-white' closeOnAction actionButtonText='Chiudi' />
      <Snackbar ref={snackbarErrorRef} icon='cross-white' iconColor='#B03E3E' closeOnAction actionButtonText='Chiudi' />
      <Snackbar ref={snackbarWarningRef} icon='cross-black' iconColor='#F1C40F' closeOnAction actionButtonText='Chiudi' />
    </>
  );
});

export default CMSTopBarContainer;
