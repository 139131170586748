/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import Navbar from "./navbar";
import Videocall from "../videochat/Videocall";
import { useStore } from "../store/storeUtils";
import { useLazyQuery, useQuery } from "@apollo/client";
import Client from "../client/Client";
import DetectUrl from "../utils/DetectUrl";
import SSOComponent from "../client/SSOComponent";
import {
  modifyTitle,
  generateManifest,
  loadCss,
  loadSettingsCSS,
  loadCustomCss,
  loadFavicon,
  preLoadAssets,
} from "../utils/DOMInjector";
import getParameterByName from "../videochat/utils/getParameterByName";
import CMSContainer from "../components/cms/CMSContainer";
import Loader from "react-loader-spinner";
import CMSTopBarContainer from "./cms/CMSTopBarContainer";
import { isCmsEnable } from "../utils/utils";
import JwtChecker from "../utils/JwtChecker";
import ServiceWorkerWrapper from "./service-worker/ServiceWorkerWrapper";

const HomePage = lazy(() => import("../pages/HomePage"));

const HeroContainer = () => {
  const store = useStore();
  const [update, setUpdate] = useState(0); // integer state
  const [initializing, setInitializing] = useState(true);
  const [forceLogout, setForceLogout] = useState(false);

  const [loadUserInfo, userInfo] = useLazyQuery(Client.GET_USER_INFO);

  //create your forceUpdate hook
  const useForceUpdate = () => {
    return () => setUpdate(update + 1); // update the state to force render
  };

  const forceUpdate = useForceUpdate();

  const roomId = getParameterByName("roomId");

  const { data, loading } = useQuery(
    Client.GET_PROJECT_INFO,
    Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl())
  );

  useEffect(() => {
    if (!store.videocallMode) {
      window.addEventListener("sw-ready", () => {
        if (!sessionStorage.getItem("swready")) {
          sessionStorage.setItem("swready", true);
          caches.has("preloadCached").then(function (cacheExist) {
            if (!cacheExist) {
              window.location.reload();
            }
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (
      sessionStorage.getItem("swready") &&
      data?.getProjectInfoByHost &&
      !store.videocallMode &&
      window.location.pathname === "/"
    ) {
      caches.has("preloadCached").then(function (cacheExist) {
        if (!cacheExist) {
          preLoadAssets(
            data.getProjectInfoByHost.neurosalesConfig,
            (numAssets) => store.setNumAssets(numAssets),
            (numAssetsLoaded) => store.setNumAssetsLoaded(numAssetsLoaded),
            (numAssetsError) => store.setNumAssetsError(numAssetsError)
          );
        }
      });
    }

    if (data?.getProjectInfoByHost) {
      store.setAssetsByObject(data.getProjectInfoByHost);
      store.setBaseUrl(data.getProjectInfoByHost.baseurl);
      loadUserInfo(Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId));

      if (data.getProjectInfoByHost.manifestConfig) {
        generateManifest(data.getProjectInfoByHost.manifestConfig);
      }

      modifyTitle(store.projectSlogan);
      loadFavicon(store.projectTitle);

      loadSettingsCSS(store.getSettings());
      loadCustomCss(store.getCustomCss());
      loadCss(store.projectTitle);
    }
  }, [data?.getProjectInfoByHost]);

  useEffect(() => {
    if (userInfo && !userInfo.loading && userInfo.called) {
      if (userInfo.data) store.setLoggedUser(userInfo.data.getUserByJWT);
      if (
        (userInfo.error || userInfo.errors) &&
        !store.skipLogin &&
        !sessionStorage.getItem("videocallMode")
      ) {
        setForceLogout(true);
      }
      setInitializing(false);
    }
  }, [
    userInfo.data,
    userInfo.loading,
    userInfo.called,
    userInfo.data,
    userInfo.error,
  ]);

  if (loading) {
    return <></>;
  }

  let subRoutesConst = [];

  const homePage = store.getHomePage();
  const routesConst =
    !initializing &&
    !roomId &&
    (!store.loggedUser || forceLogout) &&
    !store.skipLogin ? (
      <Redirect exact to="/login" />
    ) : (
      homePage?.boxes
        ?.slice()
        .sort((a, b) => (a.index > b.index ? 1 : -1))
        .map((el) => {
          if (el.componentParams && el.componentParams.components) {
            subRoutesConst.push(
              ...el.componentParams.components
                .slice()
                .sort((a, b) => (a.index > b.index ? 1 : -1))
                .map((subel) => {
                  return (
                    <Route
                      key={`${el.navigation}${subel.navigation}`}
                      path={subel.navigation}
                      render={() => {
                        store.setCurrentPage(
                          el.componentName + "#" + subel.componentName,
                          el.navigation + "#" + subel.navigation
                        );
                        return (
                          <subel.component
                            key={update}
                            subNavigation={subel.navigation}
                            tileParams={el}
                            genericParams={
                              subel.componentParams ? subel.componentParams : {}
                            }
                            superGenericParams={
                              el.componentParams ? el.componentParams : {}
                            }
                          />
                        );
                      }}
                      exact={subel.exact}
                    />
                  );
                })
            );
            return (
              <Route
                key={el.navigation}
                path={el.navigation}
                render={() => {
                  store.setCurrentPage(el.componentName, el.navigation);
                  return (
                    <el.component
                      key={update}
                      backgroundImage={el.componentParams.backgroundImage}
                      components={el.componentParams.components}
                      tileParams={el}
                      genericParams={
                        el.componentParams ? el.componentParams : {}
                      }
                    />
                  );
                }}
                exact={el.exact}
              />
            );
          } else {
            return (
              <Route
                key={el.navigation}
                path={el.navigation}
                render={() => {
                  store.setCurrentPage(el.componentName, el.navigation);
                  return (
                    <el.component
                      key={update}
                      genericParams={
                        el.componentParams ? el.componentParams : {}
                      }
                      tileParams={el}
                    />
                  );
                }}
                exact={el.exact}
              />
            );
          }
        })
    );

  return (
    <>
      <div className="white-loader" id="white-loader">
        <Loader type="RevolvingDot" color="#808080" height={100} width={100} />
      </div>

      {!initializing && (
        <div
          className={`home-container ${
            isCmsEnable(store.loggedUser) ? "with-cms-bar" : ""
          }`}
        >
          {store.baseUrl && (
            <BrowserRouter basename={store.baseUrl}>
              <ServiceWorkerWrapper baseUrl={store.baseUrl} />
              {!roomId && <JwtChecker />}
              <CMSTopBarContainer />

              <div className="heroSection">
                {/* <Router > */}
                {roomId && <Videocall />}
                <Navbar />
                <div
                  className="wrapper--100 standardBackground"
                  id="desk-tablet-content"
                >
                  <Suspense fallback={<div className="loader">Loading...</div>}>
                    {/* See https://github.com/remix-run/react-router/issues/4841 */}
                    <Route
                      path="/:url*(/+)"
                      exact
                      strict
                      render={({ location }) => (
                        <Redirect to={location.pathname.replace(/\/+$/, "")} />
                      )}
                    />
                    <Route
                      path="/"
                      render={(props) => {
                        store.setCurrentPage("HomePage", "");
                        return <HomePage {...props} />;
                      }}
                      exact
                    />
                    <Route exact path="/login" component={SSOComponent} />
                    {routesConst}
                    {subRoutesConst}
                  </Suspense>
                </div>
                <div id="mobile-content">
                  <h1 className="mobile-message">
                    Il tuo schermo è troppo piccolo.
                    <br />
                    per accedere al backend utilizza un PC.
                  </h1>
                </div>

                {isCmsEnable(store.loggedUser) ? (
                  <CMSContainer forceUpdate={forceUpdate} />
                ) : null}
              </div>
            </BrowserRouter>
          )}
        </div>
      )}
    </>
  );
};

export default HeroContainer;
