import React from 'react';
import { useStore } from '../store/storeUtils.jsx';
import FloorplanningComponent from 'floorplanning/dist/Floorplanning';
import DetectUrl from '../utils/DetectUrl';
import { useHistory } from 'react-router';
import { useSocket } from './../videochat/socketUtils';

const Floorplanning = (props) => {
  const store = useStore();
  const hostname = DetectUrl();

  const history = useHistory();
  const socket = useSocket();

  const backToNeurosales = () => {
    history.push('/');
  };

  const setUserInfo = (info) => {
    store.setLoggedUser(info);
  };

  let floorplanningBaseUrl = ""
  if(store.baseUrl && store.baseUrl.length > 0 && store.baseUrl.charAt(store.baseUrl.length - 1) !== "/") {
    floorplanningBaseUrl = `${store.baseUrl}/floorplanning/`
  }
  else {
    floorplanningBaseUrl = `${store.baseUrl}floorplanning/`
  }

  return (
    <div className='floorplanning-container'>
      <FloorplanningComponent routerBasename={floorplanningBaseUrl} forceLogin={true} socket={socket} hostname={hostname} backToNeurosales={backToNeurosales} setUserInfo={setUserInfo} />
    </div>
  );
};

export default Floorplanning;
