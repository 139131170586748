import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Store from './store/store.jsx';
import { StoreProvider } from './store/storeUtils.jsx';
import { ApolloProvider } from '@apollo/client';
import API from './client/GraphqlClient';
import { create } from 'mobx-persist';
import { SocketProvider } from './videochat/socketUtils';
import getParameterByName from './videochat/utils/getParameterByName';
import { io } from 'socket.io-client';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '@tecma/ui';

const hydrate = create({});

const store = new Store();

// Se abbiamo il parametro roomId nell'url, inizializzo la connessione con il socket
const socket =
  getParameterByName('roomId') &&
  io(process.env.REACT_APP_API, {
    path: process.env.REACT_APP_WEBSOCKET,
  });

hydrate('store', store).then(() => {
  ReactDOM.render(
    <StoreProvider value={store}>
      <ApolloProvider client={API(store)}>
        <SocketProvider value={socket}>
          <ErrorBoundary
            FallbackComponent={(props) => {
              return (
                <ErrorPage
                  logo={`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(store.projectName)}/global/img/primary-logo.svg`}
                  classes={{
                    title: 'colorFont genericErrorTitle',
                    button: 'card--icon coming-soon genericErrorButton',
                    message: 'genericErrorMessage',
                  }}
                  onClick={() => {
                    window.location.href = store.baseUrl;
                  }}
                />
              );
            }}
          >
            <App />
          </ErrorBoundary>
        </SocketProvider>
      </ApolloProvider>
    </StoreProvider>,
    document.getElementById('root'),
  );
});
