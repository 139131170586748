import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Portal from '@material-ui/core/Portal';
import Backdrop from '@material-ui/core/Backdrop';
import PropTypes from 'prop-types';

/**
 * A default loader
 * based on material-ui Circular Progress
 * @component
 * @see {@link https://material-ui.com/api/circular-progress/ Circular Progress API}
 */
const Loader = (props) => {
  const { loading, hasBackdrop, z, transparency, customClass } = props;

  const backdropColorClass = transparency ? 'backdrop-transparent' : 'backdrop-color';

  return (
    <>
      {loading &&
        (hasBackdrop ? (
          <Portal>
            <Backdrop open={loading} classes={{ root: z ? 'more-z ' + backdropColorClass + ' ' + customClass : backdropColorClass + ' ' + customClass }}>
              <CircularProgress color='inherit' />
            </Backdrop>
          </Portal>
        ) : (
          <div className={' absolute-position-relative-content-loader '}>
            <CircularProgress color='inherit' className='absolute-position-relative-content-loader' />
          </div>
        ))}
    </>
  );
};

Loader.defaultProps = {
  hasBackdrop: true,
  z: true,
  transparency: false,
  customClass: '',
};

/** prop types */
Loader.propTypes = {
  /** loading: required boolean */
  loading: PropTypes.bool.isRequired,
  /** hasBackdrop: boolean, whenever loader is at center of a backdrop or not.
   * False means it will be display relatively to its container */
  hasBackdrop: PropTypes.bool,
  /** z: boolean, controls extra z index for backdrop */
  z: PropTypes.bool,
  /** transparency: background is transparent */
  transparency: PropTypes.bool,
  customClass: PropTypes.string,
};

export default Loader;
